import axios from 'axios';
import store from '../redux/store';

function getIRequestProp(severType, isMultipart) {
  const state = store.getState();
  const serverUrl =
    process.env.NODE_ENV === 'production' ? '/' : process.env.REACT_APP_BASE_API_URL;

  return {
    serverUrl: serverUrl,
    requestHeader: {
      'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json',
      // 'Accept-Language': config.DEFAULT_LANGUAGE,
      Authorization: `Bearer ${state?.currentUser?.access_token}`
    }
  };
}
async function get(url, parameter, isJsonServer, blobResponse = false) {
  const {serverUrl, requestHeader} = getIRequestProp(isJsonServer);
  return axios.get(serverUrl + url, {
    params: parameter,
    headers: requestHeader,
    responseType: blobResponse ? 'blob' : 'json'
  });
}
async function getGoogleAPI(url) {
  return axios.get(url);
}

async function getWithoutParams(url) {
  const {serverUrl} = getIRequestProp(false);
  return axios.get(serverUrl + url);
}

async function post(url, body, isJsonServer, isMultipart) {
  const {serverUrl, requestHeader} = getIRequestProp(isJsonServer, isMultipart);
  return axios.post(serverUrl + url, body, {
    headers: requestHeader
  });
}
async function put(url, body, isJsonServer) {
  const {serverUrl, requestHeader} = getIRequestProp(isJsonServer);
  return axios.put(serverUrl + url, body, {
    headers: requestHeader
  });
}
async function patch(url, body, isJsonServer) {
  const {serverUrl, requestHeader} = getIRequestProp(isJsonServer);
  return axios.patch(serverUrl + url, body, {
    headers: requestHeader
  });
}
async function remove(url, body, isJsonServer) {
  const {serverUrl, requestHeader} = getIRequestProp(isJsonServer);
  return axios.delete(serverUrl + url, {
    data: body,
    headers: requestHeader
  });
}
const AxiosServices = {
  get,
  post,
  put,
  patch,
  getWithoutParams,
  remove,
  getGoogleAPI
};
export default AxiosServices;
