import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {BookOpen as ReadIcon, Book as UnreadIcon} from 'react-feather';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {addLeads} from 'src/redux/actions';
import {toast} from 'react-toastify';

const ViewHireDetails = ({handleClose, open, id}) => {
  const {leads} = useSelector((state) => state);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});

  const handleReadUnreadClick = (docId, status = 'unread') => {
    AxiosServices.put(ApiServices.updateLead(docId), {status})
      .then(({data}) => {
        const newDoc = leads.map((l) => (l._id === docId ? data : l));
        dispatch(addLeads(newDoc));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (id) {
      const [selectedLead] = leads.filter((l) => l._id === id);
      setDetails(selectedLead);
    }
  }, [id, leads]);
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>
        <h2>Details Information of {details.name}</h2>
      </DialogTitle>
      <DialogContent dividers>
        <h3 className="partnerTitle">Contact Information</h3>
        <table width="100%" className="partnerTable" border={1}>
          <tr>
            <td className="tableTitle">Name</td>
            <td>{details.name}</td>
          </tr>
          <tr>
            <td className="tableTitle">Email</td>
            <td>{details?.email}</td>
          </tr>
          <tr>
            <td className="tableTitle">Phone</td>
            <td>{details?.phone}</td>
          </tr>
        </table>
        <h3 className="partnerTitle">Hiring Needs</h3>
        <table width="100%" className="partnerTable" border={1}>
          <tr>
            <td className="tableTitle">Number of developers</td>
            <td>{details?.hiring_needs?.dev_no}</td>
          </tr>
          <tr>
            <td className="tableTitle">Development Type</td>
            <td>{details?.hiring_needs?.dev_type}</td>
          </tr>
          <tr>
            <td className="tableTitle">Project Type</td>
            <td>{details?.hiring_needs?.project_type}</td>
          </tr>
          <tr>
            <td className="tableTitle">Time Frame</td>
            <td>{details?.hiring_needs?.time_frame}</td>
          </tr>
        </table>
        <h3 className="partnerTitle">Technology Selected</h3>
        {details?.tech_selections?.map((tech) => {
          return <li>{tech.name}</li>;
        })}
      </DialogContent>
      <DialogActions>
        <Tooltip
          enterDelay={300}
          title={details.status === 'read' ? 'Unread' : 'Read'}
          placement="top"
        >
          <IconButton
            onClick={() =>
              handleReadUnreadClick(
                details._id,
                details.status === 'read' ? 'unread' : 'read'
              )
            }
          >
            {details.status === 'read' ? (
              <ReadIcon size="20" />
            ) : (
              <UnreadIcon size="20" />
            )}
          </IconButton>
        </Tooltip>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewHireDetails;
