import React, {useEffect, useState} from 'react';
import {
  Grid,
  Modal,
  TextField,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Button,
  ButtonGroup,
  Box
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {X as DeleteIcon} from 'react-feather';
import {generateImageUrl} from 'src/networks/utils';
import pageConfig from '../../landingpage/config/index';
import toaster from '../../../utils/Toaster/Toaster';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const FastGrowthModal = (props) => {
  const classes = useStyles();
  const {toggleModal, getList, fieldName, isOpen, updating, handleUpdateButton} =
    props;
  const [fastGrowthPointState, setFastGrowthPointState] = useState({
    isUpdating: false,
    fastGrowthPoint: {
      title: '',
      image: null
    },
    errors: {
      title: '',
      image: null
    }
  });

  const fastGrowthDataValidator = (type) => {
    const {fastGrowthPoint} = fastGrowthPointState;
    const errors = {
      ...fastGrowthPointState.errors
    };
    let hasError = false;

    Object.entries(fastGrowthPoint).forEach(([key, value]) => {
      if (!value) {
        errors[key] = errors[key] || 'Field can not be blank';
        hasError = true;
      }
    });

    Object.entries(errors).forEach(([key, value]) => {
      if (value) {
        errors[key];
        hasError = true;
      }
    });

    if (!hasError) {
      getList(fastGrowthPoint, type, fieldName);
    } else {
      setFastGrowthPointState((prevState) => {
        return {
          ...prevState,
          errors: {...errors}
        };
      });
    }

    return hasError;
  };

  const handleFastAndSafeGrowth = (type) => {
    if (type === 'cancel') {
      handleUpdateButton();
      toggleModal();
      setFastGrowthPointState((prevState) => {
        return {
          ...prevState,
          isUpdating: false,
          fastGrowthPoint: {
            title: '',
            image: null
          },
          errors: {
            title: '',
            image: null
          }
        };
      });
    } else if (type === 'save' || type === 'update') {
      const hasError = fastGrowthDataValidator(type);
      if (!hasError) {
        toggleModal();

        setFastGrowthPointState((prevState) => {
          return {
            ...prevState,
            isUpdating: false,
            fastGrowthPoint: {
              title: '',
              image: null
            }
          };
        });
      } else {
        toaster('Something went wrong', 'error');
      }
    }
  };

  const handleOnChange = (event, field) => {
    const {value} = event.target;
    const {fastGrowthPoint, errors} = fastGrowthPointState;
    if (value && value.length > pageConfig[field]) {
      setFastGrowthPointState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...errors,
            [field]: pageConfig.errorHelpText[field]
          }
        };
      });
      return;
    }
    setFastGrowthPointState((prevState) => {
      return {
        ...prevState,
        fastGrowthPoint: {
          ...fastGrowthPoint,
          [field]: value
        },
        errors: {
          ...errors,
          [field]: ''
        }
      };
    });
  };

  const fileSelectHandler = (event, fieldTitle) => {
    const allFiles = event.target.files;
    if (allFiles.length) {
      setFastGrowthPointState((prevState) => {
        return {
          ...prevState,
          fastGrowthPoint: {
            ...prevState.fastGrowthPoint,
            image: allFiles && allFiles[0]
          },
          errors: {
            ...prevState.errors,
            [fieldTitle]: null
          }
        };
      });
    }
  };

  useEffect(() => {
    if (updating) {
      setFastGrowthPointState((prevState) => {
        return {
          ...prevState,
          fastGrowthPoint: updating,
          isUpdating: true
        };
      });
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        className={classes.modal}
        onClose={() => toggleModal()}
        closeAfterTransition
        BackdropProps={{
          timeout: 300
        }}
      >
        <Card sx={{width: '60em'}}>
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={() => toggleModal()}>
                <DeleteIcon size="20" color="#dc143c" />
              </IconButton>
            }
            title={`${
              fastGrowthPointState.isUpdating ? 'Update' : 'Create a new '
            } Point`}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Title"
                  size="small"
                  error={fastGrowthPointState.errors.title ? true : false}
                  value={fastGrowthPointState.fastGrowthPoint.title}
                  onChange={(event) => handleOnChange(event, 'title')}
                  helperText={fastGrowthPointState.errors.title}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6}>
                <Box
                  height={70}
                  borderRadius={1}
                  overflow="hidden"
                  clone={fastGrowthPointState.fastGrowthPoint.image}
                >
                  {!!fastGrowthPointState.fastGrowthPoint.image && (
                    <img
                      style={{
                        height: 'inherit',
                        width: 'inherit',
                        objectFit: 'cover'
                      }}
                      src={
                        fastGrowthPointState.fastGrowthPoint.image instanceof File
                          ? window.URL.createObjectURL(
                              fastGrowthPointState.fastGrowthPoint.image
                            )
                          : generateImageUrl(
                              fastGrowthPointState.fastGrowthPoint.image
                            )
                      }
                      alt="safeIcon"
                    />
                  )}
                </Box>
                Select Safe and Fast Icon: &nbsp;
                <text style={{color: 'red'}}>( Width: 40, Height: 40 )</text>
                <input
                  type="file"
                  name="safeIcon"
                  onChange={(event) => fileSelectHandler(event, 'image')}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions disableSpacing sx={{justifyContent: 'flex-end'}}>
            <ButtonGroup
              variant="contained"
              aria-label="contained primary button group"
            >
              <Button
                color="secondary"
                onClick={() => handleFastAndSafeGrowth('cancel')}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  handleFastAndSafeGrowth(
                    fastGrowthPointState.isUpdating ? 'update' : 'save'
                  )
                }
              >
                {fastGrowthPointState.isUpdating ? 'Update' : 'Save'}
              </Button>
            </ButtonGroup>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default FastGrowthModal;
