import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton
} from '@material-ui/core';
import {
  X as DeleteIcon,
  ArrowUp as ArrowUpIcon,
  ArrowDown as ArrowDownIcon
} from 'react-feather';

const ModuleLayout = ({
  index = 0,
  lastIndex = 100,
  children,
  moduleTitle = 'Provide a Title',
  disabledCrossButton = false,
  crossClickHandler = () => {
    alert('Cliecked');
  },
  onOrderChange = (controller) => {
    alert(controller);
  },
  enableArrowButtons = false
}) => {
  const headerContents = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #ececec',
          padding: '8px 10px'
        }}
      >
        <div>
          <Typography variant="h5" component="h5">
            {moduleTitle}
          </Typography>
        </div>
        <div>
          {enableArrowButtons && (
            <>
              <IconButton
                disabled={index <= 0 ? true : false}
                onClick={() => onOrderChange(index, 'UP')}
              >
                <ArrowUpIcon size="20" />
              </IconButton>
              <IconButton
                disabled={index >= lastIndex ? true : false}
                onClick={() => onOrderChange(index, 'DOWN')}
              >
                <ArrowDownIcon size="20" />
              </IconButton>
            </>
          )}

          <IconButton
            disabled={disabledCrossButton ? true : false}
            onClick={() => crossClickHandler(index)}
          >
            <DeleteIcon
              size="20"
              color={disabledCrossButton ? '#c0c0c0' : '#dc143c'}
            />
          </IconButton>
        </div>
      </div>
    );
  };
  return (
    <>
      <Box sx={{mt: 3}}>
        <Card>
          <CardHeader component={headerContents} />
          <CardContent>{children}</CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ModuleLayout;
