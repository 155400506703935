import {Typography, Chip, Avatar} from '@material-ui/core';
import React from 'react';

const AllCapabilities = ({
  allCapabilities,
  activeCapabilities,
  setActiveCapabilities
}) => {
  return (
    <div style={{maxHeight: '400px', overflowY: 'scroll', position: 'relative'}}>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
          mb: '10px'
        }}
      >
        Available items
      </Typography>
      {allCapabilities
        .filter((c) => {
          return !activeCapabilities.find((ac) => ac._id === c._id);
        })
        .map((data) => {
          return (
            <Chip
              sx={{margin: '3px', cursor: 'pointer'}}
              avatar={
                <Avatar src={`${process.env.REACT_APP_BJIT_CMS_URL}/images/${data.image}`} />
              }
              key={data._id}
              clickable
              color="primary"
              label={data?.name}
              onClick={() =>
                setActiveCapabilities((prevState) => {
                  return [...prevState, data];
                })
              }
            />
          );
        })}
    </div>
  );
};

export default AllCapabilities;
