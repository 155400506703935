import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PartnerListResults from './PartnerListResults';
import PartnerListToolbar from './PartnerListToolbar';

const PartnerList = () => {
  const {partners} = useSelector((state) => state);
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    setSearchResult(
      partners.filter(({contact_information: {email}}) =>
        email.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, partners]);

  return (
    <>
      <PartnerListToolbar handleSearchTable={setSearchText} />
      <PartnerListResults searchResult={searchResult} />
    </>
  );
};

export default PartnerList;
