import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import NoData from '../dashboard/NoData';
import ReviewList from './ReviewList';

const ClientsReviewPageViewer = ({data: {setOptionViewer, optionViewer}}) => {
  const {reviews} = useSelector((state) => state);

  return (
    <>
      <Helmet>
        <title>Clients Review | BJIT Hire Developer</title>
      </Helmet>
      {reviews.length > 0 ? (
        <>
          <ReviewList
            setOptionViewer={setOptionViewer}
            optionViewer={optionViewer}
          />
        </>
      ) : (
        <>
          <NoData
            buttonText="Create New Review"
            setOptionViewer={setOptionViewer}
            option="CreateOrUpdateReview"
          />
        </>
      )}
    </>
  );
};

export default ClientsReviewPageViewer;
