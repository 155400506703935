import React, {useState, useEffect} from 'react';
import {Grid, TextField, Container, Autocomplete} from '@material-ui/core';
import {useSelector} from 'react-redux';
import ModuleLayout from '../../ModuleLayout';
import ActiveReviewsTable from './ActiveReviewsTable';
import AllReviews from './AllReviews';

const CustomerReviews = ({data: newData}) => {
  const {reviews} = useSelector((state) => state);
  const {
    sectionData,
    onSectionDelete,
    onOrderChange,
    index,
    lastIndex,
    onInternalDataChange,
    formik
  } = newData;
  const [activeReviews, setActiveReviews] = useState([]);

  const handleAutoCompleteChange = (value) => {
    if (value) {
      setActiveReviews((prevState) => {
        return [...prevState, value];
      });
    }
  };

  useEffect(() => {
    onInternalDataChange(index, {
      ...formik.values.sections[index].data,
      items: activeReviews
    });
  }, [activeReviews]);

  useEffect(() => {
    if (sectionData.items?.length > 0) {
      setActiveReviews(() => {
        return sectionData.items;
      });
    }
  }, []);

  return (
    <>
      <ModuleLayout
        moduleTitle="Customer Review"
        lastIndex={lastIndex}
        index={index}
        enableArrowButtons
        crossClickHandler={onSectionDelete}
        onOrderChange={onOrderChange}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                error={formik.touched.heading && formik.errors.heading}
                fullWidth
                helperText={formik.touched.heading && formik.errors.heading}
                label="Heading"
                name={`sections[${index}].data.heading`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.sections[index]?.data.heading || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                options={reviews.filter((r) => {
                  return !activeReviews.find((ar) => ar._id === r._id) && r.active;
                })}
                getOptionLabel={(option) => option.title}
                onChange={(e, v) => handleAutoCompleteChange(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Reviews Here"
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={8}>
              <ActiveReviewsTable
                activeReviews={activeReviews}
                setActiveReviews={setActiveReviews}
              />
            </Grid>
            <Grid item md={4}>
              <AllReviews
                activeReviews={activeReviews}
                setActiveReviews={setActiveReviews}
              />
            </Grid>
          </Grid>
        </Container>
      </ModuleLayout>
    </>
  );
};
export default CustomerReviews;
