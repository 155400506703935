import {useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField
} from '@material-ui/core';
import {toast} from 'react-toastify';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_CURRENT_USER} from 'src/redux/actions';

const SettingsPassword = (props) => {
  const {
    currentUser: {
      user: {_id}
    }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handlePasswordChange = (e) => {
    e.preventDefault();
    const {password, confirm} = values;
    if (password === confirm && password.length >= 8) {
      console.log(_id);
      AxiosServices.put(ApiServices.updateUser(_id), {password}, true)
        .then(() => {
          AxiosServices.get(ApiServices.logout)
            .then(() => {
              dispatch({type: ADD_CURRENT_USER, user: null});
            })
            .catch((err) => {
              toast.error(err.message);
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.error(
        'Password mismatch or Password must be at least 8 characters long'
      );
    }
  };

  return (
    <form {...props} onSubmit={handlePasswordChange}>
      <Card>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Password"
            margin="normal"
            size="small"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirm password"
            margin="normal"
            name="confirm"
            size="small"
            onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button type="submit" color="primary" variant="contained">
            Update
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default SettingsPassword;
