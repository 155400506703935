import {useState, useEffect} from 'react';
import {NavLink as RouterLink, matchPath, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Badge, Button, ListItem} from '@material-ui/core';
import {useSelector} from 'react-redux';

const NavItem = ({href, icon: Icon, title, ...rest}) => {
  const {leads, partners} = useSelector((state) => state);
  const [newLeads, setNewLeads] = useState(0);
  const [newPartners, setNewPartners] = useState(0);
  const location = useLocation();

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: true
        },
        location.pathname
      )
    : false;

  useEffect(() => {
    setNewLeads(leads.filter((l) => l.status === 'unread').length);
    setNewPartners(partners.filter((p) => p.status === 'unread').length);
  }, [leads, partners]);

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
      {...rest}
    >
      <Button
        component={RouterLink}
        sx={{
          color: 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main'
          }),
          '& svg': {
            mr: 1
          }
        }}
        to={href}
      >
        {title === 'Hiring List' && newLeads > 0 ? (
          <>
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              badgeContent={newLeads}
              color="error"
            >
              {Icon && <Icon size="20" />}
            </Badge>
          </>
        ) : title === 'Partner Requests' && newPartners > 0 ? (
          <>
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              badgeContent={newPartners}
              color="error"
            >
              {Icon && <Icon size="20" />}
            </Badge>
          </>
        ) : (
          <>{Icon && <Icon size="20" />}</>
        )}
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
