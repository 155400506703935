import React from 'react';
import {
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  InputLabel
} from '@material-ui/core';
import ModuleLayout from 'src/components/ModuleLayout';

const AddSectionDropDown = ({
  addSectionClickHandler = () => {
    alert('Add Section Click');
  },
  type = 'build_a_team',
  setType = (sectionType) => {
    alert(
      sectionType || 'Sections is required field, either not given or disabled.'
    );
  },
  sectionType = {}
}) => {
  return (
    <>
      <ModuleLayout moduleTitle="Add Section" disabledCrossButton>
        <Container>
          <Grid
            container
            alignContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item md={10}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel htmlFor="select-section-input-area">
                  Select Section
                </InputLabel>
                <Select
                  fullWidth
                  label="Select Section"
                  value={type}
                  onChange={(event) => setType(event.target.value)}
                  inputProps={{
                    id: 'select-section-input-area'
                  }}
                >
                  {sectionType.length > 0 &&
                    sectionType.map((section) => {
                      return (
                        <MenuItem key={section.id} value={section.type}>
                          {section.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => addSectionClickHandler()}
              >
                Add Section
              </Button>
            </Grid>
          </Grid>
        </Container>
      </ModuleLayout>
    </>
  );
};

export default AddSectionDropDown;
