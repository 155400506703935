import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  Container,
  Box
} from '@material-ui/core';
import {Plus as PlusIcon} from 'react-feather';
import {nanoid} from 'nanoid';
import {generateImageUrl} from 'src/networks/utils';
import ModuleLayout from '../../ModuleLayout';
import ProcessSteps from './ProcessSteps';
import ApplicationMaintenanceTable from './ApplicationMaintenanceTable';

const ApplicationMaintenance = ({data: newData}) => {
  const {
    sectionData,
    onSectionDelete,
    onOrderChange,
    index,
    lastIndex,
    onInternalDataChange,
    formik
  } = newData;
  const [applicationMaintenanceState, setApplicationMaintenanceState] = useState({
    showApplicationMaintenanceModal: false,
    data: {
      processSteps: []
    },
    updating: {
      processSteps: null
    },
    updatingPosition: {
      processSteps: null
    },
    renderTables: {
      processSteps: 0
    },
    renderModals: {
      processSteps: 0
    },
    errors: {
      processSteps: ''
    }
  });
  const handleUpdateButton = () => {
    setApplicationMaintenanceState((prevState) => {
      return {
        ...prevState,
        updating: {processSteps: null}
      };
    });
  };
  const toggleModal = () => {
    setApplicationMaintenanceState((prevState) => {
      return {
        ...prevState,
        showApplicationMaintenanceModal:
          !applicationMaintenanceState.showApplicationMaintenanceModal
      };
    });
  };
  const handleClose = () => {
    setApplicationMaintenanceState((prevState) => {
      return {
        ...prevState,
        showApplicationMaintenanceModal: false
      };
    });
  };
  const getList = (obj, type, fieldName) => {
    const {updating, renderTables, updatingPosition, data} =
      applicationMaintenanceState;
    if (type === 'update') {
      const list = [...data[fieldName]];
      list.splice(updatingPosition[fieldName], 1, obj);
      setApplicationMaintenanceState((prevState) => {
        return {
          ...prevState,
          data: {
            ...data,
            [fieldName]: [...list]
          },
          renderTables: {
            ...renderTables,
            [fieldName]: renderTables[fieldName] + 1
          },
          updating: {
            ...updating,
            [fieldName]: null
          }
        };
      });
    } else {
      setApplicationMaintenanceState((prevState) => {
        return {
          ...prevState,
          data: {
            ...data,
            [fieldName]: [...data[fieldName], obj]
          },
          renderTables: {
            ...renderTables,
            [fieldName]: renderTables[fieldName] + 1
          },
          errors: {
            ...applicationMaintenanceState.errors,
            [fieldName]: null
          }
        };
      });
    }
  };
  const handleUpdate = (pos, fieldName) => {
    const {data, renderTables, updating, renderModals, updatingPosition} =
      applicationMaintenanceState;
    const list = [...data[fieldName]];
    const [update] = list.splice(pos, 1);
    setApplicationMaintenanceState((prevState) => {
      return {
        ...prevState,
        updating: {
          ...updating,
          [fieldName]: update
        },
        updatingPosition: {
          ...updatingPosition,
          [fieldName]: pos
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        },
        renderModals: {
          ...renderModals,
          [fieldName]: renderModals[fieldName] + 1
        },
        showApplicationMaintenanceModal: true
      };
    });
  };

  const handleDelete = (list, fieldName) => {
    const {data, renderTables} = applicationMaintenanceState;
    setApplicationMaintenanceState((prevState) => {
      return {
        ...prevState,
        data: {
          ...data,
          [fieldName]: [...list]
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        }
      };
    });
  };

  const handleReOrder = (list, fieldName) => {
    const {data, renderTables} = applicationMaintenanceState;
    setApplicationMaintenanceState((prevState) => {
      return {
        ...prevState,
        data: {
          ...data,
          [fieldName]: [...list]
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        }
      };
    });
  };

  useEffect(() => {
    onInternalDataChange(index, {
      ...formik.values.sections[index].data,
      points: applicationMaintenanceState.data.processSteps
    });
  }, [applicationMaintenanceState.data.processSteps]);

  useEffect(() => {
    if (sectionData?.points?.length > 0) {
      setApplicationMaintenanceState((prevState) => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            processSteps: sectionData.points
          }
        };
      });
    }
  }, []);

  return (
    <>
      <ModuleLayout
        moduleTitle="Application Maintenance"
        lastIndex={lastIndex}
        index={index}
        enableArrowButtons
        crossClickHandler={onSectionDelete}
        onOrderChange={onOrderChange}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                error={formik.touched.email && formik.errors.email}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Heading"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                size="small"
                variant="outlined"
                name={`sections[${index}].data.heading`}
                value={formik.values.sections[index]?.data.heading || ''}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                rows={3}
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={`sections[${index}].data.description`}
                value={formik.values.sections[index]?.data.description || ''}
              />
            </Grid>
            {/* <Grid item md={6}>
              Upload a Image: &nbsp;
              
            </Grid> */}
            <Grid item md={5}>
              <Box
                height={180}
                borderRadius={1}
                overflow="hidden"
                clone={formik.values.sections[index]?.data?.image}
              >
                {!!formik.values.sections[index]?.data?.image && (
                  <img
                    style={{
                      height: 'inherit',
                      width: 'inherit',
                      objectFit: 'cover'
                    }}
                    src={
                      formik.values.sections[index]?.data?.image instanceof File
                        ? window.URL.createObjectURL(
                            formik.values.sections[index]?.data?.image
                          )
                        : generateImageUrl(
                            formik.values.sections[index]?.data?.image
                          )
                    }
                    alt="hire-developer"
                  />
                )}
              </Box>
              Select Cover Image: &nbsp;
              <text style={{color: 'red'}}>( Width: 550, Height: 415 )</text>
              <input
                type="file"
                onChange={(e) =>
                  formik.setFieldValue(
                    `sections[${index}].data.image`,
                    e.target.files[0]
                  )
                }
              />
            </Grid>
            <Grid item md={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h4" component="h4">
                  Process Steps*
                </Typography>
                {/* Show error if empty */}
                {formik.errors.concept ? (
                  <Typography variant="h6" component="h6" color="error">
                    {formik.errors.concept}
                  </Typography>
                ) : (
                  ''
                )}

                <IconButton onClick={() => toggleModal()}>
                  <PlusIcon size="20" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <ApplicationMaintenanceTable
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                handleReOrder={handleReOrder}
                key={applicationMaintenanceState.renderTables.processSteps}
                fieldName="processSteps"
                data={applicationMaintenanceState.data.processSteps}
              />
            </Grid>
          </Grid>
        </Container>
        <ProcessSteps
          getList={getList}
          key={nanoid()}
          isOpen={applicationMaintenanceState.showApplicationMaintenanceModal}
          handleClose={handleClose}
          toggleModal={toggleModal}
          handleUpdateButton={handleUpdateButton}
          fieldName="processSteps"
          updating={applicationMaintenanceState.updating.processSteps}
        />
      </ModuleLayout>
    </>
  );
};
export default ApplicationMaintenance;
