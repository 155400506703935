import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {ArrowUp as ArrowUpIcon, ArrowDown as ArrowDownIcon} from 'react-feather';
import {nanoid} from 'nanoid';
import {generateImageUrl} from 'src/networks/utils';

const columns = [
  {
    id: 'order',
    label: 'Order',
    align: 'center'
  },
  {
    id: 'bulletImage',
    label: 'Image',
    align: 'center'
  },
  {
    id: 'title',
    label: 'Title',
    minWidth: 200,
    align: 'center'
  },
  {
    id: 'subTitle',
    label: 'Sub Title',
    minWidth: 200,
    align: 'center',
    format: (value) => value.toLocaleString('en-US')
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'center'
  }
];

const BulletPointTable = (props) => {
  const {
    handleUpdate = () => {
      alert('Update Clicked');
    },
    handleDelete = () => {
      alert('Delete Clicked');
    },
    handleReOrder = () => {
      alert('Reorder Clicked');
    },
    fieldName,
    data
  } = props;
  const [bulletPointsSubSectionState, setBulletPointsSubSectionState] = useState({
    rows: []
  });

  const handleOrderChange = (direction, pos) => {
    let to = 0;
    if (direction === 'up') {
      to = pos === 0 ? 0 : pos - 1;
    }
    if (direction === 'down') {
      to = pos === data.length ? pos : pos + 1;
    }
    const dataList = [...data];
    const movedItem = dataList.splice(pos, 1)[0];
    // insert stored item into position
    dataList.splice(to, 0, movedItem);
    handleReOrder(dataList, fieldName);
  };

  const handleDeleteWork = (pos) => {
    const works = [...data];
    works.splice(pos, 1);
    handleDelete(works, fieldName);
  };

  const handleUpdateWork = (pos) => {
    handleUpdate(pos, fieldName);
  };

  const createData = (bulletImage, title, subTitle, pos) => {
    const bulletPicture = (
      <Box overflow="hidden" clone>
        <img
          height={50}
          width={50}
          style={{objectFit: 'fill'}}
          src={
            bulletImage instanceof File
              ? window.URL.createObjectURL(bulletImage)
              : generateImageUrl(bulletImage)
          }
          alt={title}
        />
      </Box>
    );
    const order = (
      <div key={pos}>
        <IconButton onClick={() => handleOrderChange('up', pos)}>
          <ArrowUpIcon />
        </IconButton>
        <IconButton onClick={() => handleOrderChange('down', pos)}>
          <ArrowDownIcon />
        </IconButton>
      </div>
    );
    const action = (
      <div key={pos}>
        <Button
          onClick={() => handleUpdateWork(pos)}
          size="small"
          color="primary"
          variant="outlined"
        >
          Edit
        </Button>
        &nbsp;
        <Button
          onClick={() => handleDeleteWork(pos)}
          size="small"
          color="secondary"
          variant="outlined"
        >
          Delete
        </Button>
      </div>
    );

    return {order, bulletImage: bulletPicture, title, subTitle, action};
  };

  useEffect(() => {
    const dataRows = [];
    data &&
      data.forEach((d, pos) => {
        dataRows.push(createData(d.image, d.title, d.subTitle, pos));
      });
    setBulletPointsSubSectionState((prevState) => {
      return {
        ...prevState,
        rows: dataRows
      };
    });
  }, [data]);

  return (
    <>
      <TableContainer style={{maxHeight: 300}}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bulletPointsSubSectionState.rows.length > 0 ? (
              <>
                {bulletPointsSubSectionState.rows.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={nanoid()}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={nanoid()} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <>
                <TableRow hover>
                  <TableCell align="center" colSpan={5}>
                    <h4>Nothing to show</h4>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BulletPointTable;
