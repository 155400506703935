import React, {useState} from 'react';
import {Box, Container} from '@material-ui/core';
import LandingPageViewer from 'src/components/landingpage/LandingPageViewer';
import CreateOrUpdateFrontPage from 'src/components/landingpage/CreateOrUpdateFrontPage';

const sectionType = {
  FrontPageViewer: (data) => (
    <LandingPageViewer setOptionViewer={data.setOptionViewer} />
  ),
  CreateOrUpdateFrontPage: (data) => (
    <CreateOrUpdateFrontPage
      setOptionViewer={data.setOptionViewer}
      isUpdate={data.optionViewer.isUpdating}
    />
  )
};

const FrontPage = () => {
  const [optionViewer, setOptionViewer] = useState({
    id: 'FrontPageViewer',
    isUpdating: ''
  });
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {sectionType[optionViewer.id] &&
            sectionType[optionViewer.id]({optionViewer, setOptionViewer})}
        </Container>
      </Box>
    </>
  );
};

export default FrontPage;
