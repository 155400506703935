import {Box, Container, Divider, Typography} from '@material-ui/core';
import React from 'react';
import {Helmet} from 'react-helmet';
import MailSettingsViewer from 'src/components/mailing-settings';

const MailingSettings = () => {
  return (
    <>
      <Helmet>
        <title>Mail Settings | BJIT Hire Developer</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <MailSettingsViewer />
      </Box>
    </>
  );
};

export default MailingSettings;
