import React, {useState, useEffect} from 'react';
import {
  Grid,
  Modal,
  TextField,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Button,
  ButtonGroup,
  Backdrop,
  Box
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import {checkImageWidthAndHeight} from 'src/utils/imageHelpers';
import {X as DeleteIcon} from 'react-feather';
import pageConfig from '../../landingpage/config/index';
import toaster from '../../../utils/Toaster/Toaster';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const CarouselData = (props) => {
  const classes = useStyles();
  const {toggleModal, getList, fieldName, isOpen, handleUpdateButton, updating} =
    props;
  const initialState = {
    isUpdating: false,
    carouselData: {
      title: '',
      subTitle: '',
      description: '',
      author: '',
      authorDesignation: '',
      image: null
    },
    error: {
      title: '',
      subTitle: '',
      description: '',
      author: '',
      authorDesignation: '',
      image: null
    }
  };
  const [carouselDataState, setCarouselDataState] = useState({
    ...initialState
  });
  const validator = (type) => {
    const {carouselData} = carouselDataState;
    const error = {
      ...carouselData.error
    };
    let hasError = false;

    Object.entries(carouselData).forEach(([key, value]) => {
      if (!value) {
        error[key] = error[key] || 'Field can not be blank';
        hasError = true;
      }
    });
    Object.entries(error).forEach(([key, value]) => {
      if (value) {
        error[key];
        hasError = true;
      }
    });
    if (!hasError) {
      getList(carouselData, type, fieldName);
    } else {
      setCarouselDataState((prevState) => {
        return {
          ...prevState,
          error: {...error}
        };
      });
    }
    return hasError;
  };
  const handleSubSectionSave = (type) => {
    if (type === 'cancel') {
      handleUpdateButton();
      toggleModal();
      setCarouselDataState((prevState) => {
        // console.log(carouselDataState)
        return {
          ...prevState,
          ...initialState
        };
      });
    } else if (type === 'save' || type === 'update') {
      const hasError = validator(type);
      if (!hasError) {
        toggleModal();
        // handleClose();
        setCarouselDataState((prevState) => {
          console.log(carouselDataState);
          return {
            ...prevState,
            isUpdating: false,
            carouselData: {
              title: '',
              subTitle: '',
              description: '',
              author: '',
              authorDesignation: '',
              image: null
            }
          };
        });
      } else {
        toaster('Something went wrong', 'error');
      }
    }
  };
  const handleOnChange = (event, field) => {
    const {value} = event.target;
    const {carouselData, error} = carouselDataState;

    if (value && value.length > pageConfig[field]) {
      setCarouselDataState((prevState) => {
        return {
          ...prevState,
          error: {
            ...error,
            [field]: pageConfig.errorHelpText[field]
          }
        };
      });
      return;
    }

    setCarouselDataState((prevState) => {
      return {
        ...prevState,
        carouselData: {
          ...carouselData,
          [field]: value
        },
        error: {
          ...error,
          [field]: ''
        }
      };
    });
  };
  const fileSelectHandler = (event, fieldTitle) => {
    const allFiles = event.target.files;
    const imageConfig = pageConfig.subSection.image;
    if (allFiles.length) {
      checkImageWidthAndHeight(allFiles, imageConfig)
        .then(() => {
          setCarouselDataState((prevState) => {
            return {
              ...prevState,
              carouselData: {
                ...prevState.carouselData,
                image: allFiles && allFiles[0]
              },
              errors: {
                ...prevState.error,
                [fieldTitle]: null
              }
            };
          });
        })
        .catch(() => {
          setCarouselDataState((prevState) => {
            return {
              ...prevState,
              errors: {
                ...prevState.error,
                [fieldTitle]: pageConfig.errorHelpText[fieldTitle]
              }
            };
          });
        });
    }
  };
  useEffect(() => {
    if (updating) {
      setCarouselDataState((prevState) => {
        return {
          ...prevState,
          carouselData: updating,
          isUpdating: true
        };
      });
    }
  }, []);

  return (
    <>
      <>
        <Modal
          open={isOpen}
          className={classes.modal}
          onClose={() => toggleModal()}
          closeAfterTransition
          BackdropProps={{
            timeout: 300
          }}
        >
          <Card sx={{width: '60em'}}>
            <CardHeader
              action={
                <IconButton aria-label="settings" onClick={() => toggleModal()}>
                  <DeleteIcon size="20" color="#dc143c" />
                </IconButton>
              }
              title={`${
                carouselDataState.isUpdating ? 'Update' : 'Save'
              } a Subsection Data`}
            />
            <CardContent>
              <Grid container spacing={1}>
                {/* Title */}
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    error={carouselDataState.error.title ? true : false}
                    value={carouselDataState.carouselData.title}
                    // style={{marginTop: 10}}
                    fullWidth
                    onChange={(event) => handleOnChange(event, 'title')}
                    // margin="normal"
                    helperText={carouselDataState.error.title}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      // shrink: true,
                      style: {}
                    }}
                  />
                </Grid>
                {/* Title End */}
                {/* SubTitle */}
                <Grid item xs={12}>
                  <TextField
                    label="Sub Title"
                    error={carouselDataState.error.subTitle ? true : false}
                    value={carouselDataState.carouselData.subTitle}
                    // style={{marginTop: 10}}
                    fullWidth
                    onChange={(event) => handleOnChange(event, 'subTitle')}
                    // margin="normal"
                    helperText={carouselDataState.error.subTitle}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      // shrink: true,
                      style: {}
                    }}
                  />
                </Grid>
                {/* Subtitle End */}
                {/* Description */}
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    error={carouselDataState.error.description ? true : false}
                    value={carouselDataState.carouselData.description}
                    // style={{marginTop: 10}}
                    fullWidth
                    multiline
                    rows={3}
                    onChange={(event) => handleOnChange(event, 'description')}
                    // margin="normal"
                    helperText={carouselDataState.error.description}
                    required
                    variant="outlined"
                    InputLabelProps={{
                      // shrink: true,
                      style: {}
                    }}
                  />
                </Grid>
                {/* Description End */}
                {/* Author */}
                <Grid item xs={12}>
                  <TextField
                    error={carouselDataState.error.author ? true : false}
                    label="Author"
                    value={carouselDataState.carouselData.author}
                    style={{margin: 0}}
                    onChange={(event) => handleOnChange(event, 'author')}
                    helperText={carouselDataState.error.author}
                    fullWidth
                    // margin="normal"
                    required
                    variant="outlined"
                    InputLabelProps={{
                      // shrink: true,
                      style: {}
                    }}
                  />
                </Grid>
                {/* Author Designation */}
                <Grid item xs={12}>
                  <TextField
                    error={carouselDataState.error.authorDesignation ? true : false}
                    label="Author Designation"
                    value={carouselDataState.carouselData.authorDesignation}
                    style={{margin: 0}}
                    onChange={(event) => handleOnChange(event, 'authorDesignation')}
                    helperText={carouselDataState.error.authorDesignation}
                    fullWidth
                    // margin="normal"
                    required
                    variant="outlined"
                    InputLabelProps={{
                      // shrink: true,
                      style: {}
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    height={70}
                    borderRadius={1}
                    overflow="hidden"
                    clone={carouselDataState.carouselData.image}
                  >
                    {!!carouselDataState.carouselData.image && (
                      <img
                        style={{
                          height: 'inherit',
                          width: 'inherit',
                          objectFit: 'cover'
                        }}
                        src={
                          carouselDataState.carouselData.image instanceof File
                            ? window.URL.createObjectURL(
                                carouselDataState.carouselData.image
                              )
                            : carouselDataState.carouselData.image
                        }
                        alt="safeIcon"
                      />
                    )}
                  </Box>
                  Select Carousel Image: &nbsp;
                  <text style={{color: 'red'}}>( Width: 477, Height: 520 )</text>
                  <input
                    type="file"
                    name="realStoriesImage"
                    onChange={(event) => fileSelectHandler(event, 'image')}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions disableSpacing sx={{justifyContent: 'flex-end'}}>
              <ButtonGroup
                variant="contained"
                aria-label="contained primary button group"
              >
                <Button
                  color="secondary"
                  onClick={() => handleSubSectionSave('cancel')}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={() =>
                    handleSubSectionSave(
                      carouselDataState.isUpdating ? 'update' : 'save'
                    )
                  }
                >
                  {carouselDataState.isUpdating ? 'Update' : 'Save'}
                </Button>
              </ButtonGroup>
            </CardActions>
          </Card>
        </Modal>
      </>
    </>
  );
};
export default CarouselData;
