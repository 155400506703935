/* eslint-disable camelcase */
import {useEffect, useRef, useState} from 'react';
import {
  Box,
  Card,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@material-ui/core';
import {
  X as DeleteIcon,
  Eye as EyeIcon,
  Check as CheckIcon,
  Printer as PrinterIcon
} from 'react-feather';
import {nanoid} from 'nanoid';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {addLeads} from 'src/redux/actions';
import moment from 'moment';
import ViewHireDetails from './ViewHireDetails';
import DeleteConfirmationHireRequest from '../common/DeleteConfirmationHireRequest/DeleteConfirmationHireRequest';

const columns = [
  {
    id: 'createdAt',
    label: 'Date',
    align: 'center',
    minWidth: '150px'
  },
  {
    id: 'name',
    label: 'Name',
    align: 'center'
  },
  {
    id: 'email',
    label: 'Email',
    align: 'center'
  },
  {
    id: 'hiring_option',
    label: 'Hiring Option',
    align: 'center'
  },
  {
    id: 'project_type',
    label: 'Project Type',
    align: 'center'
  },
  {
    id: 'assignee',
    label: 'Assigned To',
    align: 'center'
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'center',
    minWidth: '150px'
  }
];

const HireListResults = ({searchResult}) => {
  const {leads} = useSelector((state) => state);
  const nameRef = useRef([]);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirmMessage, setOpenConfirmMessage] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectForDelete, setSelectForDelete] = useState('');

  const handleClickOpen = (id) => {
    setSelectForDelete(id);
    setOpenConfirmMessage(true);
  };

  const handleCloseMessage = () => {
    setOpenConfirmMessage(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewClick = (id) => {
    setSelectedItem(id);
    setOpen(true);
  };

  const deleteHandler = () => {
    AxiosServices.remove(ApiServices.deleteLead(selectForDelete))
      .then(() => {
        const newDoc = leads.filter((l) => l._id !== selectForDelete);
        dispatch(addLeads(newDoc));
        setPage(newDoc.length % limit >= 0 ? page : page - 1);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        handleCloseMessage();
      });
  };

  const handleNameSave = (id) => {
    if (!nameRef.current[id].value) {
      toast.error('Must Provide a name');
    } else if (nameRef.current[id].value.length <= 6) {
      toast.error('Must Provide with 6 characters long');
    } else {
      AxiosServices.put(ApiServices.updateLead(id), {
        assignee: nameRef.current[id].value
      })
        .then(({data}) => {
          const newDoc = leads.map((l) => (l._id === id ? data : l));
          dispatch(addLeads(newDoc));
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  const createData = (
    _id,
    createdAt,
    name,
    email,
    hiring_option,
    project_type,
    assignee,
    status
  ) => {
    const action = (
      <>
        <Tooltip title="View" placement="top">
          <IconButton onClick={() => handleViewClick(_id)}>
            <EyeIcon size="20" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Print" placement="top" disabled>
          <IconButton>
            <PrinterIcon size="20" color="#0000ff" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleClickOpen(_id)}>
            <DeleteIcon size="20" color="#dc143c" />
          </IconButton>
        </Tooltip>
      </>
    );

    const assginedToBox = (
      <>
        <div style={{display: 'flex'}}>
          <TextField
            inputRef={(el) => {
              nameRef.current[_id] = el;
            }}
            label="Enter Name"
            size="small"
          />
          <Tooltip title="Proceed">
            <IconButton onClick={() => handleNameSave(_id)}>
              <CheckIcon size="20" color="#4BB543" />
            </IconButton>
          </Tooltip>
        </div>
      </>
    );

    return {
      _id,
      createdAt: moment(createdAt).format('DD-MMM-YYYY'),
      name,
      email,
      hiring_option,
      project_type,
      assignee: assignee ? assignee : assginedToBox,
      status,
      action
    };
  };

  useEffect(() => {
    const dataRows = [];
    searchResult &&
      searchResult.forEach(({hiring_needs, ...d}) => {
        dataRows.push(
          createData(
            d._id,
            d.createdAt,
            d.name,
            d.email,
            d.hiring_option,
            hiring_needs.project_type,
            d.assignee,
            d.status
          )
        );
      });
    setRows(dataRows);
  }, [limit, page, searchResult]);

  return (
    <>
      <Card>
        <Box sx={{minWidth: 1050}}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{maxWidth: column.maxWidth, minWidth: column.minWidth}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                <>
                  {rows.slice(limit * page, limit * page + limit).map((row) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={nanoid()}
                        selected={row.status === 'unread' ? true : false}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              <TableCell key={nanoid()} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <>
                  <TableRow hover>
                    <TableCell align="center" colSpan={6}>
                      <h4>Nothing to show</h4>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={rows.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <ViewHireDetails open={open} handleClose={handleClose} id={selectedItem} />
      <DeleteConfirmationHireRequest
        deleteHandler={deleteHandler}
        handleClose={handleCloseMessage}
        open={openConfirmMessage}
      />
    </>
  );
};

export default HireListResults;
