import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  TextField
} from '@material-ui/core';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {addMails} from 'src/redux/actions';

const initialValues = {
  email: ''
};

const AddMail = ({type}) => {
  const {emailList} = useSelector((state) => state);
  const dispatch = useDispatch();
  const handleSubmit = async (values, {resetForm}) => {
    AxiosServices.post(ApiServices.addNewMail, {...values, category: type}, true)
      .then(({data}) => {
        dispatch(addMails([...emailList, data]));
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        resetForm();
      });
  };
  return (
    <Card>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Email is required field')
            .email('Must be valid email address')
        })}
      >
        {(formik) => {
          return (
            <Form autoComplete="off">
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextField
                    fullWidth
                    required
                    type="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    name="email"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    value={formik.values?.email || ''}
                  />
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button type="submit" color="primary" fullWidth variant="text">
                  Add
                </Button>
              </CardActions>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default AddMail;
