// Control the Validation by changing the values
const newPage = {
  name: 100,
  tagline: 100,
  description: 500,
  cover: {
    height: 10,
    width: 10
  },
  banner: {
    height: 10,
    width: 10
  },
  clientLogo: {
    height: 10,
    width: 10
  },
  solutionTitle: 100,
  solutionText: 500,
  solutionImage: {
    height: 10,
    width: 10
  }
};

const bulletPoint = {
  title: 50,
  text: 300,
  image: {
    height: 10,
    width: 10
  }
};
const fastGrowthPoint = {
  title: 50,
  text: 500,
  image: {
    height: 10,
    width: 10
  }
};
const singleFaqPoint = {
  title: 50,
  image: {
    height: 10,
    width: 10
  }
};
const benefits = {
  benefitsTitle: 50,
  benefitsIcon: {
    height: 10,
    width: 10
  },
  benefitsImage: {
    height: 10,
    width: 10
  }
};
const subSection = {
  subTitle: 50,
  description: 200,
  image: {
    height: 10,
    width: 10
  },
  arrow: {
    height: 10,
    width: 10
  }
};
const carouselData = {
  description: 200,
  author: 50,
  authorDesignation: 50,
  image: {
    height: 10,
    weidth: 10
  }
};
const processStep = {
  title: 50,
  description: 200
};

export default {
  ...newPage,
  bulletPoint: {
    ...bulletPoint
  },
  singleFaqPoint: {
    ...singleFaqPoint
  },
  fastGrowthPoint: {
    ...fastGrowthPoint
  },
  benefits: {
    ...benefits
  },
  subSection: {
    ...subSection
  },
  carouselData: {
    ...carouselData
  },
  processStep: {
    ...processStep
  },
  // when user give wrong input
  errorHelpText: {
    cover: `Image size should not be less than ${newPage.cover.width} X ${newPage.cover.height}`,
    banner: `Image size should not be less than ${newPage.banner.width} X ${newPage.banner.height}`,
    clientLogo: `Image size should not be less than ${newPage.clientLogo.width} X ${newPage.clientLogo.height}`,
    name: `Should not be longer than ${newPage.name} character`,
    tagline: `Should not be longer than ${newPage.tagline} character`,
    description: `Should not be longer than ${newPage.description} character`,
    solutionTitle: `Should not be longer than ${newPage.solutionTitle} character`,
    solutionText: `Should not be longer than ${newPage.solutionText} character`,
    solutionImage: `Image size should not be less than ${newPage.solutionImage.width} X ${newPage.solutionImage.height} `,
    // bullet point
    title: `Should not be longer than ${bulletPoint.title} character`,
    text: `Should not be longer than ${bulletPoint.text} character`,
    image: `Image size should not be less than ${bulletPoint.image.width} X ${bulletPoint.image.height} `,
    // benefits
    benefitsTitle: `Should not be longer than ${benefits.benefitsTitle} character`,
    benefitsIcon: `Image size should not be less than ${benefits.benefitsIcon.width} X ${benefits.benefitsIcon.height} `,
    benefitsImage: `Image size should not be less than ${benefits.benefitsImage.width} X ${benefits.benefitsImage.height} `
  }
};
