import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import HireListResults from './HireListResults';
import HireListToolbar from './HireListToolbar';

const HireList = () => {
  const {leads} = useSelector((state) => state);
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    setSearchResult(
      leads.filter(({email}) =>
        email.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, leads]);

  return (
    <>
      <HireListToolbar handleSearchTable={setSearchText} />
      <HireListResults searchResult={searchResult} />
    </>
  );
};

export default HireList;
