import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import HireList from './HireList';

const HireRequestListViwer = () => {
  const {leads} = useSelector((state) => state);

  return (
    <>
      <Helmet>
        <title>Hire Request List | BJIT Hire Developer</title>
      </Helmet>
      {leads.length > 0 ? (
        <>
          <HireList />
        </>
      ) : (
        <>
          <h1>No Request is available</h1>
        </>
      )}
    </>
  );
};

export default HireRequestListViwer;
