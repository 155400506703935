import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import PartnerList from './PartnerList';

const PartnerRequestListViwer = () => {
  const {partners} = useSelector((state) => state);
  return (
    <>
      <Helmet>
        <title>Partner Request List | BJIT Hire Developer</title>
      </Helmet>
      {partners.length >= 0 ? (
        <>
          <PartnerList />
        </>
      ) : (
        <>
          <h1>No Request is available</h1>
        </>
      )}
    </>
  );
};

export default PartnerRequestListViwer;
