import {Box, Grid, TextField, Container} from '@material-ui/core';
import React from 'react';
import ModuleLayout from 'src/components/ModuleLayout';
import {generateImageUrl} from 'src/networks/utils';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const HireDeveloper = ({data}) => {
  const {
    sectionData,
    onSectionDelete,
    onOrderChange,
    index,
    lastIndex,
    onInternalDataChange,
    formik
  } = data;

  const fileSelectHandler = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const inputName = event.target.name.split('.').pop();
    onInternalDataChange(index, {
      ...formik.values.sections[index].data,
      [inputName]: file
    });
    event.target.value = null;
  };

  return (
    <>
      <ModuleLayout
        lastIndex={lastIndex}
        index={index}
        enableArrowButtons
        crossClickHandler={onSectionDelete}
        onOrderChange={onOrderChange}
        moduleTitle="Hire Developer"
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <RichTextEditor
                wrapperText="Title"
                value={formik.values.sections[index]?.data.heading || ''}
                onChangeFn={formik.handleChange}
                inputName={`sections[${index}].data.heading`}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Description"
                variant="outlined"
                size="small"
                name={`sections[${index}].data.description`}
                value={formik.values.sections[index]?.data.description || ''}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="First Button Text"
                size="small"
                name={`sections[${index}].data.firstButtonText`}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.sections[index]?.data.firstButtonText || ''}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                label="Second Button Text"
                size="small"
                name={`sections[${index}].data.secondButtonText`}
                value={formik.values.sections[index]?.data.secondButtonText || ''}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item md={6}>
              <Box
                height={180}
                borderRadius={1}
                overflow="hidden"
                clone={formik.values.sections[index]?.data?.image}
              >
                {!!formik.values.sections[index]?.data?.image && (
                  <img
                    style={{
                      height: 'inherit',
                      width: 'inherit',
                      objectFit: 'cover'
                    }}
                    src={
                      formik.values.sections[index]?.data?.image instanceof File
                        ? window.URL.createObjectURL(
                            formik.values.sections[index]?.data?.image
                          )
                        : generateImageUrl(
                            formik.values.sections[index]?.data?.image
                          )
                    }
                    alt="hire-developer"
                  />
                )}
              </Box>
              Select Cover Image: &nbsp;
              <text style={{color: 'red'}}>( Width: 445, Height: 453 )</text>
              <input
                type="file"
                name={`$sections[${index}].data.image`}
                onChange={(event) => fileSelectHandler(event)}
              />
            </Grid>
            <Grid item md={6}>
              <Box
                height={180}
                borderRadius={1}
                overflow="hidden"
                clone={formik.values.sections[index]?.data?.background_image}
              >
                {!!formik.values.sections[index]?.data?.background_image && (
                  <img
                    style={{
                      width: '350px',
                      aspectRatio: '16/9',
                      objectFit: 'cover'
                    }}
                    src={
                      formik.values.sections[index]?.data
                        ?.background_image instanceof File
                        ? window.URL.createObjectURL(
                            formik.values.sections[index]?.data?.background_image
                          )
                        : generateImageUrl(
                            formik.values.sections[index]?.data?.background_image
                          )
                    }
                    alt="hire-developer_background_image"
                  />
                )}
              </Box>
              Select Background Image: &nbsp;
              <text style={{color: 'red'}}>( Width: 1440, Height: 660 )</text>
              <input
                type="file"
                name={`$sections[${index}].data.background_image`}
                onChange={(event) => fileSelectHandler(event)}
              />
            </Grid>
          </Grid>
        </Container>
      </ModuleLayout>
    </>
  );
};

export default HireDeveloper;
