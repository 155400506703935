import store from 'src/redux/store';
import {ADD_CURRENT_USER} from '../redux/actions';

const timerLength = 1200;

function getMember() {
  const state = store.getState();
  const dispatch = store.dispatch();
  const userInReduxState = state?.currentUser?.access_token || '';
  if (!userInReduxState) return null;

  try {
    const member = JSON.parse(atob(userInReduxState));
    const issuedSeconds = isNaN(member.issuedSeconds) ? 0 : member.issuedSeconds; // Convert from string to number
    const secondsSinceSignIn =
      Math.floor(new Date().getTime() / 1000) - issuedSeconds;
    const sessionSecondsRemaining = timerLength - secondsSinceSignIn;
    member.sessionSecondsRemaining = sessionSecondsRemaining;

    if (sessionSecondsRemaining <= 0) {
      dispatch({type: ADD_CURRENT_USER, user: null});
      return null;
    }

    return member;
  } catch (err) {
    // Local storage has been tampered with
    dispatch({type: ADD_CURRENT_USER, user: null});
    return null;
  }
}

export default getMember;
