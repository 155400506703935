import {Box, Container, Grid, Typography, Button} from '@material-ui/core';

const NoData = ({buttonText = '', setOptionViewer, option = ''}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Box sx={{textAlign: 'center'}}>
            <img
              alt="Under development"
              src="/static/images/nodata-red.png"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: 460
              }}
            />
          </Box>
          <Box>
            <Grid container spacing={3}>
              <Grid item lg={8}>
                <Typography align="center" color="textPrimary" variant="h2">
                  Not newly created or can face data.
                </Typography>
              </Grid>
              <Grid item lg={4}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOptionViewer({id: option, isUpdating: ''})}
                >
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NoData;
