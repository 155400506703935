import React from 'react';
import {IconButton, Container, Box, Tooltip} from '@material-ui/core';
import {RefreshCw as RefreshIcon, Download as DownloadIcon} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import {setRefresh} from 'src/redux/actions';
import {CSVLink} from 'react-csv';
import PartnerRequestListViwer from 'src/components/partnerList';

const PartnerRequest = () => {
  const {partners} = useSelector((state) => state);
  const dispatch = useDispatch();

  const headers = [
    {
      label: 'Company Name',
      key: 'company_information.name'
    },
    {
      label: '',
      key: ''
    }
  ];

  const csvReport = {
    filename: 'Partner List.csv',
    headers: headers,
    data: partners
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <CSVLink {...csvReport}>
              <Tooltip title="Export to CSV" placement="top">
                <IconButton sx={{ml: 5}}>
                  <DownloadIcon size="30" color="#bb2124" />
                </IconButton>
              </Tooltip>
            </CSVLink>
            <Tooltip title="Refresh" placement="top">
              <IconButton onClick={() => dispatch(setRefresh(true))}>
                <RefreshIcon size="30" />
              </IconButton>
            </Tooltip>
          </div>
          <PartnerRequestListViwer />
        </Container>
      </Box>
    </>
  );
};

export default PartnerRequest;
