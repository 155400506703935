/* eslint-disable import/prefer-default-export */
export const checkImageWidthAndHeight = (files, {width, height}) => {
  return new Promise((resolve, reject) => {
    const file = new File([files[0]], files[0].name);
    const src = window.URL.createObjectURL(file);
    const image = new Image();
    image.src = src;
    image.onload = function () {
      if (this.width < width || this.height < height) {
        reject(new Error('something bad happened'));
      } else {
        resolve(true);
      }
    };
  });
};
