import {Button, Box} from '@material-ui/core';
import {useFormik} from 'formik';
import {nanoid} from 'nanoid';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import * as Yup from 'yup';
import ApiServices from 'src/networks/ApiServices';
import AxiosServices from 'src/networks/AxiosService';
import buildFormData from 'src/networks/utils';
import toaster from 'src/utils/Toaster/Toaster';
import promise from 'src/utils/handlers';
import AddSectionDropDown from '../common/AddSectionDropDown/AddSectionDropDown';
import ApplicationMaintenance from '../common/ApplicationMaintenance/ApplicationMaintenance';
import ClientsOfBjit from '../common/ClientsOfBjit/ClientsOfBjit';
import CustomerReviews from '../common/CustomerReviews/CustomerReviews';
import FaqSection from '../common/FaqSection/FaqSection';
import HireDeveloper from '../common/HireDeveloper/HireDeveloper';
import OurCapabilities from '../common/OurCapabilities/OurCapabilities';
import RealStories from '../common/RealStories/RealStories';
import RemoteDeveloper from '../common/RemoteDeveloper/RemoteDeveloper';
import SafeAndFastGrowth from '../common/SafeAndFastGrowth/SafeAndFastGrowth';
import WhyBjitSuitable from '../common/WhyBjitSuitable/WhyBjitSuitable';
import ModuleLayout from '../ModuleLayout';

const schema = Yup.object().shape({
  // title: Yup.string().required().min(6),
  // description: Yup.string().required(),
  // category: Yup.string().required(),
  // owner: Yup.string().required(),
  // date: Yup.string().required(),
  sections: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        active: Yup.boolean(),
        type: Yup.string().required(),
        data: Yup.object()
      })
    )
    .min(2)
});

const initialValues = {
  // title: '',
  // description: '',
  // category: '',
  // owner: 'BJIT Inc.',
  // ownerImage: null,
  // cover: null,
  // thumbnail: null,
  // feature: null,
  // date: (new Date).toISOString().slice(0,10),
  sections: []
};

const sectionType = [
  {
    type: 'hire_developer',
    title: 'Hire Developer',
    id: nanoid()
  },
  {
    type: 'top_rated_hire_developer',
    title: 'Top Rated Hire Developer',
    id: nanoid()
  },
  {
    type: 'safe_and_fast_growth',
    title: 'Safe and Fast Growth',
    id: nanoid()
  },
  {
    type: 'why_bjit_suitable',
    title: 'Why BJIT Suitable',
    id: nanoid()
  },
  {
    type: 'real_legend_stories',
    title: 'Real Legend Stories',
    id: nanoid()
  },
  {
    type: 'our_capabilites',
    title: 'Our Capabilities',
    id: nanoid()
  },
  {
    type: 'faq_section',
    title: 'Faq Section',
    id: nanoid()
  },
  {
    type: 'application_maintenance',
    title: 'Application Maintenance',
    id: nanoid()
  },
  {
    type: 'bjit_clients',
    title: 'Clients of BJIT',
    id: nanoid()
  },
  {
    type: 'customer_review',
    title: 'Customer Review',
    id: nanoid()
  }
];

const sectionViews = {
  hire_developer: (data) => <HireDeveloper key={data.key} data={data} />,
  top_rated_hire_developer: (data) => <RemoteDeveloper key={data.key} data={data} />,
  safe_and_fast_growth: (data) => <SafeAndFastGrowth key={data.key} data={data} />,
  why_bjit_suitable: (data) => <WhyBjitSuitable key={data.key} data={data} />,
  real_legend_stories: (data) => <RealStories key={data.key} data={data} />,
  faq_section: (data) => <FaqSection key={data.key} data={data} />,
  application_maintenance: (data) => (
    <ApplicationMaintenance key={data.key} data={data} />
  ),
  bjit_clients: (data) => <ClientsOfBjit key={data.key} data={data} />,
  our_capabilites: (data) => <OurCapabilities key={data.key} data={data} />,
  customer_review: (data) => <CustomerReviews key={data.key} data={data} />
};

const CreateOrUpdateFrontPage = ({isUpdate = ''}) => {
  const [type, setType] = useState('hire_developer');

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const newSections = values.sections.map((s) => {
          return s.type.includes('customer_review')
            ? {
                ...s,
                data: {
                  ...s.data,
                  items: s.data.items.map((r) => r._id)
                }
              }
            : s.type.includes('bjit_clients')
            ? {
                ...s,
                data: {...s.data, clientsList: s.data.clientsList.map((c) => c._id)}
              }
            : s;
        });
        const formData = new FormData();
        buildFormData(formData, {...values, sections: newSections});

        const response = AxiosServices.post(
          ApiServices.createLandingPage,
          formData,
          false,
          true
        );
        toaster(
          `${isUpdate ? 'Updated' : 'Created'} Sucessfully`,
          'success',
          response.data
        );
        if (!values._id) formik.setValues(initialValues);
      } catch (err) {
        toaster(err.message, 'error', {});
      } finally {
        formik.setSubmitting(false);
      }
    }
  });

  const addSection = () => {
    const newSections = [
      ...formik.values.sections,
      {
        key: nanoid(),
        active: true,
        type: type,
        data: {}
      }
    ];
    formik.setFieldValue('sections', newSections);
  };

  const sectionOrderHandler = (index, order) => {
    const from = index;
    const to = index + (order === 'UP' ? -1 : 1);

    const newSections = [...formik.values.sections];
    if (to < 0 || to > newSections.length - 1) return;
    const item = newSections.splice(from, 1)[0];
    newSections.splice(to, 0, item);
    formik.setFieldValue('sections', newSections);
  };

  const sectionDataHandler = (index, data) => {
    const newSections = [
      ...formik.values.sections.slice(0, index),
      {
        ...formik.values.sections[index],
        data: data
      },
      ...formik.values.sections.slice(index + 1)
    ];
    formik.setFieldValue('sections', newSections);
  };

  const sectionDeleteHandler = (index) => {
    const newSections = [...formik.values.sections];
    newSections.splice(index, 1);
    formik.setFieldValue('sections', newSections);
  };

  useEffect(() => {
    if (!!isUpdate) {
      const fetch = async () => {
        const [response, error] = await promise(
          AxiosServices.get(ApiServices.getPage, {id: isUpdate})
        );
        formik.setValues({
          ...response.data
        });
      };
      fetch();
    }
  }, [isUpdate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet>
        <title>
          {isUpdate ? `Update` : `Create`} Front Page | BJIT Hire Developer
        </title>
      </Helmet>
      <ModuleLayout moduleTitle="Meta Data" disabledCrossButton>
        <h1>This is Create or Update Component</h1>
      </ModuleLayout>
      {formik.values.sections.map((section, index) => {
        return (
          <>
            {sectionViews[section?.type] &&
              sectionViews[section?.type]({
                key: section.id,
                sectionData: section?.data,
                onOrderChange: sectionOrderHandler,
                onInternalDataChange: sectionDataHandler,
                onSectionDelete: sectionDeleteHandler,
                index,
                lastIndex: formik.values?.sections?.length - 1,
                formik
              })}
          </>
        );
      })}
      <AddSectionDropDown
        type={type}
        setType={setType}
        addSectionClickHandler={addSection}
        sectionType={sectionType}
      />
      <Box textAlign="center" sx={{py: 2}}>
        <Button
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          style={{width: '300px'}}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
        >
          {isUpdate ? 'Update' : 'Create'}
        </Button>
      </Box>
    </form>
  );
};

export default CreateOrUpdateFrontPage;
