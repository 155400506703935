import {
  Grid,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import {Search as SearchIcon} from 'react-feather';

const HireListToolbar = (props) => {
  const {handleSearchTable = (v) => console.log(v)} = props;
  return (
    <Card sx={{mt: 3}}>
      <CardContent>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item md={12}>
            <TextField
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search By Email..."
              onChange={(e) => handleSearchTable(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HireListToolbar;
