import {Container, Grid, IconButton, TextField, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Plus as PlusIcon} from 'react-feather';
import {nanoid} from 'nanoid';
import ModuleLayout from 'src/components/ModuleLayout';
import SafeAndFastGrowthTable from './SafeAndFastGrowthTable';
import FastGrowthModal from './FastGrowthModal';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

const SafeAndFastGrowth = ({data: newData}) => {
  const {
    sectionData,
    onSectionDelete,
    onOrderChange,
    index,
    lastIndex,
    onInternalDataChange,
    formik
  } = newData;

  const [safeAndfastGrowthState, setSafeAndfastGrowthState] = useState({
    showSafeAndfastGrowthModal: false,
    data: {
      fastGrowthPoints: []
    },
    updating: {
      fastGrowthPoints: null
    },
    updatingPosition: {
      fastGrowthPoints: null
    },
    renderTables: {
      fastGrowthPoints: 0
    },
    renderModals: {
      fastGrowthPoints: 0
    },
    errors: {
      fastGrowthPoints: ''
    }
  });

  const toggleModal = () => {
    setSafeAndfastGrowthState((prevState) => {
      return {
        ...prevState,
        showSafeAndfastGrowthModal:
          !safeAndfastGrowthState.showSafeAndfastGrowthModal
      };
    });
  };
  const handleUpdateButton = () => {
    setSafeAndfastGrowthState((prevState) => {
      return {
        ...prevState,
        updating: {fastGrowthPoints: null}
      };
    });
  };

  const getList = (obj, type, fieldName) => {
    const {updating, renderTables, updatingPosition, data} = safeAndfastGrowthState;
    if (type === 'update') {
      const list = [...data[fieldName]];
      list.splice(updatingPosition[fieldName], 1, obj);
      setSafeAndfastGrowthState((prevState) => {
        return {
          ...prevState,
          data: {
            ...data,
            [fieldName]: [...list]
          },
          renderTables: {
            ...renderTables,
            [fieldName]: renderTables[fieldName] + 1
          },
          updating: {
            ...updating,
            [fieldName]: null
          }
        };
      });
    } else {
      setSafeAndfastGrowthState((prevState) => {
        return {
          ...prevState,
          data: {
            ...data,
            [fieldName]: [...data[fieldName], obj]
          },
          renderTables: {
            ...renderTables,
            [fieldName]: renderTables[fieldName] + 1
          },
          errors: {
            ...safeAndfastGrowthState.errors,
            [fieldName]: null
          }
        };
      });
    }
  };

  const handleUpdate = (pos, fieldName) => {
    const {data, renderTables, updating, renderModals, updatingPosition} =
      safeAndfastGrowthState;
    const list = [...data[fieldName]];
    const [update] = list.splice(pos, 1);
    setSafeAndfastGrowthState((prevState) => {
      return {
        ...prevState,
        updating: {
          ...updating,
          [fieldName]: update
        },
        updatingPosition: {
          ...updatingPosition,
          [fieldName]: pos
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        },
        renderModals: {
          ...renderModals,
          [fieldName]: renderModals[fieldName] + 1
        },
        showSafeAndfastGrowthModal: true
      };
    });
  };

  const handleDelete = (list, fieldName) => {
    const {data, renderTables} = safeAndfastGrowthState;
    setSafeAndfastGrowthState((prevState) => {
      return {
        ...prevState,
        data: {
          ...data,
          [fieldName]: [...list]
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        }
      };
    });
  };

  const handleReOrder = (list, fieldName) => {
    const {data, renderTables} = safeAndfastGrowthState;
    setSafeAndfastGrowthState((prevState) => {
      return {
        ...prevState,
        data: {
          ...data,
          [fieldName]: [...list]
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        }
      };
    });
  };

  useEffect(() => {
    onInternalDataChange(index, {
      ...formik.values.sections[index].data,
      points: safeAndfastGrowthState.data.fastGrowthPoints
    });
  }, [safeAndfastGrowthState.data.fastGrowthPoints]);

  useEffect(() => {
    if (sectionData?.points?.length > 0) {
      setSafeAndfastGrowthState((prevState) => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            fastGrowthPoints: sectionData.points
          }
        };
      });
    }
  }, []);

  return (
    <>
      <ModuleLayout
        moduleTitle="Safe and Fast Growth"
        lastIndex={lastIndex}
        index={index}
        enableArrowButtons
        crossClickHandler={onSectionDelete}
        onOrderChange={onOrderChange}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <RichTextEditor
                value={formik.values.sections[index]?.data.heading || ''}
                onChangeFn={formik.handleChange}
                inputName={`sections[${index}].data.heading`}
                wrapperText="Heading"
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                rows={3}
                name={`sections[${index}].data.description`}
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                size="small"
                value={formik.values.sections[index]?.data.description || ''}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item md={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h4" component="h4">
                  Add New*
                </Typography>
                {/* Show error if empty */}
                {formik.errors.concept ? (
                  <Typography variant="h6" component="h6" color="error">
                    {formik.errors.concept}
                  </Typography>
                ) : (
                  ''
                )}
                <IconButton onClick={() => toggleModal()}>
                  <PlusIcon size="20" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <SafeAndFastGrowthTable
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                handleReOrder={handleReOrder}
                key={safeAndfastGrowthState.renderTables.fastGrowthPoints}
                fieldName="fastGrowthPoints"
                data={safeAndfastGrowthState.data.fastGrowthPoints}
              />
            </Grid>
          </Grid>
        </Container>
        <FastGrowthModal
          getList={getList}
          key={nanoid()}
          isOpen={safeAndfastGrowthState.showSafeAndfastGrowthModal}
          toggleModal={toggleModal}
          fieldName="fastGrowthPoints"
          handleUpdateButton={handleUpdateButton}
          updating={safeAndfastGrowthState.updating.fastGrowthPoints}
        />
      </ModuleLayout>
    </>
  );
};

export default SafeAndFastGrowth;
