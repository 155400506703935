const ApiServices = {
  // auth
  login: `user/login`,
  createUser: `user/create`,
  logout: `user/logout`,
  allUsers: `user`,
  removeUser: (id) => `user/${id}`,
  updateUser: (id) => `user/${id}`,

  // our clients
  getAllClients: `bjitclients`,
  createClient: `bjitclients`,
  getClient: `bjitclients/one`,
  removeClient: (id) => `bjitclients/${id}`,
  updateClient: (id) => `bjitclients/${id}`,

  // landing page
  getLandingPage: `landingpage`,
  getPage: `landingpage/one`,
  createLandingPage: `landingpage`,
  sectionActive: `landingpage`,
  updateLandingPage: (id) => `landingpage/${id}`,

  // reviews
  getAllReviews: `reviews`,
  createReview: `reviews`,
  getReview: `reviews/one`,
  removeReview: (id) => `reviews/${id}`,
  updateReview: (id) => `reviews/${id}`,

  // leads
  getAllLeads: `leads`,
  updateLead: (id) => `leads/${id}`,
  deleteLead: (id) => `leads/${id}`,

  // partner request
  getAllPartners: `partners`,
  getSinglePartner: `partners/one`,
  updatePartnerRequest: (id) => `partners/${id}`,
  deletePartnerLead: (id) => `partners/${id}`,

  // email service
  getAllMails: `mails`,
  addNewMail: `mails`,
  deleteMail: (id) => `mails/${id}`,

  // common
  updateActiveStatus: (prefix, id) => `${prefix}/active/${id}`
};
export default ApiServices;
