import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import NoData from '../dashboard/NoData';
import ClientsList from './clientsList';

const OurClientsPageViewer = ({data: {optionViewer, setOptionViewer}}) => {
  const {clients} = useSelector((state) => state);

  return (
    <>
      <Helmet>
        <title>Our Clients | BJIT Hire Developer</title>
      </Helmet>
      {clients.length > 0 ? (
        <>
          <ClientsList
            allClients={clients}
            setOptionViewer={setOptionViewer}
            optionViewer={optionViewer}
          />
        </>
      ) : (
        <>
          <NoData
            buttonText="Create New Client"
            setOptionViewer={setOptionViewer}
            option="CreateOrUpdateClients"
          />
        </>
      )}
    </>
  );
};

export default OurClientsPageViewer;
