import {
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Typography,
  IconButton,
  Button,
  TablePagination,
  Box
} from '@material-ui/core';
import {nanoid} from 'nanoid';
import React, {useEffect, useState} from 'react';
import {ArrowUp as ArrowUpIcon, ArrowDown as ArrowDownIcon} from 'react-feather';
import Truncate from 'src/components/helper/Truncate';

const columns = [
  {
    id: 'order',
    label: 'Order',
    align: 'center'
  },
  {
    id: 'name',
    label: 'Reviewer',
    align: 'center'
  },
  {
    id: 'body',
    label: 'Review',
    align: 'center'
  },
  {
    id: 'active',
    label: 'Currently',
    align: 'center'
  },
  {
    id: 'action',
    label: 'Action',
    minWidth: 170,
    align: 'center'
  }
];

const ActiveReviewsTable = ({activeReviews, setActiveReviews}) => {
  const [tableRows, setTableRows] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleOrderChange = (controller, pos) => {
    let to = 0;
    if (controller === 'up') {
      to = pos === 0 ? 0 : pos - 1;
    }
    if (controller === 'down') {
      to = pos === activeReviews.length ? pos : pos + 1;
    }
    const dataList = [...activeReviews];
    const movedItem = dataList.splice(pos, 1)[0];
    // insert stored item into position
    dataList.splice(to, 0, movedItem);
    setActiveReviews(dataList);
  };

  const createData = (_id, name, body, active, pos) => {
    const order = (
      <div key={pos}>
        <IconButton disabled={pos <= 0} onClick={() => handleOrderChange('up', pos)}>
          <ArrowUpIcon />
        </IconButton>
        <IconButton
          disabled={pos === activeReviews.length - 1}
          onClick={() => handleOrderChange('down', pos)}
        >
          <ArrowDownIcon />
        </IconButton>
      </div>
    );
    const activeBox = (
      <>
        <h6 style={{color: active ? '#108C06' : '#FF9080'}}>
          {active ? 'active' : 'inactive'}
        </h6>
      </>
    );
    const action = (
      <div key={pos}>
        <Button
          onClick={() =>
            setActiveReviews((prevState) => prevState.filter((c) => c._id !== _id))
          }
          size="small"
          color="secondary"
          variant="outlined"
        >
          Delete
        </Button>
      </div>
    );

    return {
      _id,
      order,
      name,
      body: Truncate(body, 25),
      active: activeBox,
      action
    };
  };

  useEffect(() => {
    const dataRows = [];
    activeReviews &&
      activeReviews.forEach(({clientName, ...d}, pos) => {
        dataRows.push(createData(d._id, d.authorName, d.body, d.active, pos));
      });
    setTableRows(() => {
      return dataRows;
    });
  }, [activeReviews, limit, page]);

  return (
    <>
      <Typography variant="h5" component="h5" sx={{mb: '10px'}}>
        Active{' '}
        <span sx={{color: 'red'}}>{`(${activeReviews.length || 0} items)`}</span>
      </Typography>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.length > 0 ? (
              <>
                {tableRows.slice(limit * page, limit * page + limit).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={nanoid()}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={nanoid()} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <>
                <TableRow hover>
                  <TableCell align="center" colSpan={5}>
                    <h4>Nothing to show</h4>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={activeReviews.length}
          onPageChange={(e, p) => setPage(p)}
          onRowsPerPageChange={(e) => setLimit(e.target.value)}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
    </>
  );
};

export default ActiveReviewsTable;
