import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import ClientsListResults from './ClientsListResults';
import ClientsListToolbar from './ClientsListToolbar';

const ClientsList = (props) => {
  const {clients} = useSelector((state) => state);
  const {setOptionViewer} = props;
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    setSearchResult(() =>
      clients.filter((c) => c.name.toLowerCase().includes(searchText.toLowerCase()))
    );
  }, [searchText, clients]);

  return (
    <>
      <ClientsListToolbar
        setOptionViewer={setOptionViewer}
        handleSearchTable={setSearchText}
      />
      <ClientsListResults
        searchResult={searchResult}
        setOptionViewer={setOptionViewer}
        setSearchResult={setSearchResult}
      />
    </>
  );
};

export default ClientsList;
