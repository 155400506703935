import {Helmet} from 'react-helmet';
import {Box, Container, Grid} from '@material-ui/core';
import AccountProfileDetails from 'src/components/account/AccountProfileDetails';
import AccoutLists from 'src/components/account/AccoutLists';

const SettingsView = () => (
  <>
    <Helmet>
      <title>Settings | Material Kit</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <AccountProfileDetails />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <AccoutLists />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default SettingsView;
