import React from 'react';
import ReactQuill from 'react-quill';
import './custom.css';

export default function RichTextEditor(props) {
  const modules = {
    toolbar: [
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        'link',
        {color: ['#000000', '#008744', '#0057e7', '#d62d20', '#ffa700', '#ffffff']}
      ]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'link',
    'color'
  ];
  return (
    <fieldset className="editor_wrapper">
      <legend className="editor_for">{props.wrapperText || 'Description'}</legend>
      <ReactQuill
        className="wrapper"
        formats={formats}
        modules={modules}
        value={props.value}
        onChange={(v) =>
          props.onChangeFn({
            target: {
              name: props.inputName,
              value: v
            }
          })
        }
      />
    </fieldset>
  );
}
