import {Container, Box} from '@material-ui/core';
import React, {useState} from 'react';
import OurClientsPageViewer from 'src/components/ourclients';
import CreateOrUpdateClients from 'src/components/ourclients/CreateOrUpdateClients';

const sectionType = {
  OurClientsPageViewer: (data) => <OurClientsPageViewer data={data} />,
  CreateOrUpdateClients: (data) => (
    <CreateOrUpdateClients
      data={data}
      setOptionViewer={data.setOptionViewer}
      isUpdate={data.optionViewer.isUpdating}
    />
  )
};

const OurClients = () => {
  const [optionViewer, setOptionViewer] = useState({
    id: 'OurClientsPageViewer',
    isUpdating: ''
  });
  // console.log(optionViewer);
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container>
          {sectionType[optionViewer.id] &&
            sectionType[optionViewer.id]({optionViewer, setOptionViewer})}
        </Container>
      </Box>
    </>
  );
};

export default OurClients;
