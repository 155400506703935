import {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Layout as LayoutIcon,
  Lock as LockIcon,
  Slack as SlackIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  MessageSquare as MessageSquareIcon,
  Users as UsersIcon,
  Sliders as SlidersIcon,
  List as ListIcon
} from 'react-feather';
import {useSelector} from 'react-redux';
import {generateImageUrl} from 'src/networks/utils';
import Roles from 'src/roles';
import NavItem from './NavItem';

const items = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN]
  },
  {
    href: '/dashboard/landingpage',
    icon: LayoutIcon,
    title: 'Landing Page',
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN]
  },
  {
    href: '/dashboard/clients',
    icon: SlackIcon,
    title: 'Our Clients',
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN]
  },
  {
    href: '/dashboard/reviews',
    icon: MessageSquareIcon,
    title: 'Clients Reviews',
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN]
  },
  {
    href: '/dashboard/hiring-list',
    icon: ListIcon,
    title: 'Hiring List',
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.SALES]
  },
  {
    href: '/dashboard/mailing-settings',
    icon: SlidersIcon,
    title: 'Mail Settings',
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.SALES]
  },
  {
    href: '/dashboard/partners',
    icon: UsersIcon,
    title: 'Partner Requests',
    roles: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.SALES]
  },
  {
    href: '/dashboard/admins',
    icon: LockIcon,
    title: 'Users & Roles',
    roles: [Roles.SUPER_ADMIN]
  }
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Log In'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const DashboardSidebar = ({onMobileClose, openMobile}) => {
  const location = useLocation();
  const {currentUser} = useSelector((state) => state);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={generateImageUrl(currentUser.user.picture)}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/dashboard/account"
        />
        <Typography color="textPrimary" variant="h5">
          {currentUser.user.fullName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {currentUser.user.role.toUpperCase()}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{p: 2}}>
        <List>
          {items
            .filter((i) =>
              i.roles.find(
                (r) => r.toLowerCase() === currentUser.user.role.toLowerCase()
              )
            )
            .map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
        </List>
      </Box>
      <Box sx={{flexGrow: 1}} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
