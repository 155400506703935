import React, {useEffect} from 'react';
import {Grid, TextField, Typography, IconButton, Container} from '@material-ui/core';
import {Plus as PlusIcon} from 'react-feather';
import {nanoid} from 'nanoid';
import ModuleLayout from '../../ModuleLayout';
import CarouselData from './CarouselData';
import RealStoriesTable from './RealStoriesTable';

const RealStories = ({data: newData}) => {
  const {
    sectionData,
    onSectionDelete,
    onOrderChange,
    onInternalDataChange,
    index,
    lastIndex,
    formik
  } = newData;
  const [realStoriesState, setRealStoriesState] = React.useState({
    showRealStoriesModal: false,
    data: {
      carouselDatas: []
    },
    updating: {
      carouselDatas: null
    },
    updatingPosition: {
      carouselDatas: null
    },
    renderTables: {
      carouselDatas: 0
    },
    renderModals: {
      carouselDatas: 0
    },
    errors: {
      carouselDatas: ''
    }
  });
  const handleUpdateButton = () => {
    setRealStoriesState((prevState) => {
      return {
        ...prevState,
        updating: {processSteps: null}
      };
    });
  };

  const toggleModal = () => {
    setRealStoriesState((prevState) => {
      return {
        ...prevState,
        showRealStoriesModal: !realStoriesState.showRealStoriesModal
      };
    });
  };
  const handleClose = () => {
    setRealStoriesState((prevState) => {
      return {
        ...prevState,
        showRealStoriesModal: false
      };
    });
  };
  const getList = (obj, type, fieldName) => {
    const {updating, renderTables, updatingPosition, data} = realStoriesState;
    if (type === 'update') {
      const list = [...data[fieldName]];
      list.splice(updatingPosition[fieldName], 1, obj);
      setRealStoriesState((prevState) => {
        return {
          ...prevState,
          data: {
            ...data,
            [fieldName]: [...list]
          },
          renderTables: {
            ...renderTables,
            [fieldName]: renderTables[fieldName] + 1
          },
          updating: {
            ...updating,
            [fieldName]: null
          }
        };
      });
    } else {
      setRealStoriesState((prevState) => {
        return {
          ...prevState,
          data: {
            ...data,
            [fieldName]: [...data[fieldName], obj]
          },
          renderTables: {
            ...renderTables,
            [fieldName]: renderTables[fieldName] + 1
          },
          errors: {
            ...realStoriesState.errors,
            [fieldName]: null
          }
        };
      });
    }
  };

  const handleUpdate = (pos, fieldName) => {
    const {data, renderTables, updating, renderModals, updatingPosition} =
      realStoriesState;
    const list = [...data[fieldName]];
    const [update] = list.splice(pos, 1);
    setRealStoriesState((prevState) => {
      return {
        ...prevState,
        updating: {
          ...updating,
          [fieldName]: update
        },
        updatingPosition: {
          ...updatingPosition,
          [fieldName]: pos
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        },
        renderModals: {
          ...renderModals,
          [fieldName]: renderModals[fieldName] + 1
        },
        showRealStoriesModal: true
      };
    });
  };

  const handleDelete = (list, fieldName) => {
    const {data, renderTables} = realStoriesState;
    setRealStoriesState((prevState) => {
      return {
        ...prevState,
        data: {
          ...data,
          [fieldName]: [...list]
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        }
      };
    });
  };

  const handleReOrder = (list, fieldName) => {
    const {data, renderTables} = realStoriesState;
    setRealStoriesState((prevState) => {
      return {
        ...prevState,
        data: {
          ...data,
          [fieldName]: [...list]
        },
        renderTables: {
          ...renderTables,
          [fieldName]: renderTables[fieldName] + 1
        }
      };
    });
  };

  useEffect(() => {
    onInternalDataChange(index, {
      ...formik.values.sections[index].data,
      points: realStoriesState.data.carouselDatas
    });
  }, [realStoriesState.data.carouselDatas]);

  useEffect(() => {
    if (sectionData?.points?.length > 0) {
      setRealStoriesState((prevState) => {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            carouselDatas: sectionData.points
          }
        };
      });
    }
  }, []);

  return (
    <>
      <ModuleLayout
        moduleTitle="Real Legend Stories"
        lastIndex={lastIndex}
        index={index}
        enableArrowButtons
        crossClickHandler={onSectionDelete}
        onOrderChange={onOrderChange}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h4" component="h4">
                  Carousal Data*{' '}
                </Typography>
                {/* Show error if empty */}
                {formik.errors.concept ? (
                  <Typography variant="h6" component="h6" color="error">
                    {formik.errors.concept}
                  </Typography>
                ) : (
                  ''
                )}

                <IconButton onClick={() => toggleModal()}>
                  <PlusIcon size="20" />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <RealStoriesTable
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                handleReOrder={handleReOrder}
                key={realStoriesState.renderTables.carouselDatas}
                fieldName="carouselDatas"
                data={realStoriesState.data.carouselDatas}
              />
            </Grid>
          </Grid>
        </Container>
        <CarouselData
          getList={getList}
          key={nanoid()}
          isOpen={realStoriesState.showRealStoriesModal}
          handleClose={handleClose}
          toggleModal={toggleModal}
          handleUpdateButton={handleUpdateButton}
          fieldName="carouselDatas"
          updating={realStoriesState.updating.carouselDatas}
        />
      </ModuleLayout>
    </>
  );
};
export default RealStories;
