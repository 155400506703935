import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {toast} from 'react-toastify';
import ApiServices from 'src/networks/ApiServices';
import AxiosServices from 'src/networks/AxiosService';
import NoData from '../dashboard/NoData';
import LandingPageTable from './LandingPageTable';

const LandingPageViewer = ({setOptionViewer}) => {
  const [landingPage, setLandingPage] = useState([]);

  useEffect(() => {
    AxiosServices.get(ApiServices.getLandingPage, {})
      .then(({data}) => {
        setLandingPage(data.list);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Front Page | BJIT Hire Developer</title>
      </Helmet>
      {landingPage.length > 0 ? (
        <>
          <LandingPageTable
            landingPage={landingPage}
            setOptionViewer={setOptionViewer}
          />
        </>
      ) : (
        <>
          <NoData
            buttonText="Create New Page"
            setOptionViewer={setOptionViewer}
            option="CreateOrUpdateFrontPage"
          />
        </>
      )}
    </>
  );
};

export default LandingPageViewer;
