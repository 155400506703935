import React from 'react';
import {IconButton, Container, Box, Tooltip} from '@material-ui/core';
import HireRequestListViwer from 'src/components/hireList';
import {RefreshCw as RefreshIcon, Download as DownloadIcon} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import {setRefresh} from 'src/redux/actions';
import {CSVLink} from 'react-csv';

const HiringList = () => {
  const {leads} = useSelector((state) => state);
  const dispatch = useDispatch();

  const headers = [
    {
      label: 'Lead Initiator Name',
      key: 'name'
    },
    {
      label: 'Email',
      key: 'email'
    },
    {
      label: 'Phone No.',
      key: 'phone'
    },
    {
      label: 'Hiring Option',
      key: 'hiring_option'
    },
    {
      label: 'Tech Selections',
      key: 'tech_selections'
    },
    {
      label: 'Development Type',
      key: 'hiring_needs.dev_type'
    },
    {
      label: 'No. of Developers Needed',
      key: 'hiring_needs.dev_no'
    },
    {
      label: 'Project Type',
      key: 'hiring_needs.project_type'
    },
    {
      label: 'Time Selection',
      key: 'hiring_needs.time_frame'
    }
  ];

  const csvReport = {
    filename: 'Hiring Leads.csv',
    headers: headers,
    data: leads
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <CSVLink {...csvReport}>
              <Tooltip title="Export to CSV" placement="top">
                <IconButton sx={{ml: 5}}>
                  <DownloadIcon size="30" color="#bb2124" />
                </IconButton>
              </Tooltip>
            </CSVLink>
            <Tooltip title="Refresh">
              <IconButton onClick={() => dispatch(setRefresh(true))}>
                <RefreshIcon size="30" />
              </IconButton>
            </Tooltip>
          </div>
          <HireRequestListViwer />
        </Container>
      </Box>
    </>
  );
};

export default HiringList;
