import {Container, Box} from '@material-ui/core';
import React, {useState} from 'react';
import ClientsReviewPageViewer from 'src/components/clientsReview';
import CreateOrUpdateReview from 'src/components/clientsReview/CreateOrUpdateReview';

const sectionType = {
  OurReviewsPageViewer: (data) => <ClientsReviewPageViewer data={data} />,
  CreateOrUpdateReview: (data) => (
    <CreateOrUpdateReview data={data} isUpdate={data.optionViewer.isUpdating} />
  )
};

const ClientsReviews = () => {
  const [optionViewer, setOptionViewer] = useState({
    id: 'OurReviewsPageViewer',
    isUpdating: ''
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container>
          {sectionType[optionViewer.id] &&
            sectionType[optionViewer.id]({optionViewer, setOptionViewer})}
        </Container>
      </Box>
    </>
  );
};

export default ClientsReviews;
