import {Grid, TextField, Autocomplete, Container} from '@material-ui/core';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import ModuleLayout from 'src/components/ModuleLayout';
import ActiveCapabilitiesTable from './ActiveCapabilitiesTable';
import AllCapabilities from './AllCapabilities';

const OurCapabilities = ({data}) => {
  const {
    sectionData,
    onSectionDelete,
    onOrderChange,
    onInternalDataChange,
    index,
    lastIndex,
    formik
  } = data;
  const [allCapabilities, setAllCapabilities] = useState([]);

  const [activeCapabilities, setActiveCapabilities] = useState([]);

  const handleAutoCompleteChange = (value) => {
    if (value) {
      setActiveCapabilities((prevState) => {
        return [...prevState, value];
      });
    }
  };

  useEffect(() => {
    onInternalDataChange(index, {
      ...formik.values.sections[index].data,
      capabilities: activeCapabilities.map((cap) => {
        return {
          _id: cap._id,
          name: cap.name,
          image: cap.image
        };
      })
    });
  }, [activeCapabilities]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BJIT_CMS_URL}/capability/get-all-group-by-category`
      )
      .then((response) => {
        const returnedData = response.data.results.capabilities || [];
        const rows = [];
        Object.entries(returnedData).forEach(([, value]) => {
          value.forEach((d) => {
            rows.push(d);
          });
        });
        setAllCapabilities(() => {
          return rows;
        });
      })
      .catch((error) => {
        console.error(error);
        const {message} = error;
        setAllCapabilities((prevState) => {
          return {
            ...prevState,
            isLoading: false
          };
        });
        toast.error(message);
      });
  }, []);

  useEffect(() => {
    if (sectionData.capabilities?.length > 0) {
      setActiveCapabilities(() => {
        return sectionData.capabilities;
      });
    }
  }, []);

  return (
    <>
      <ModuleLayout
        moduleTitle="Our Capabilities"
        crossClickHandler={onSectionDelete}
        onOrderChange={onOrderChange}
        index={index}
        lastIndex={lastIndex}
        enableArrowButtons
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                fullWidth
                type="text"
                size="small"
                variant="outlined"
                label="Enter the Heading"
                name={`sections[${index}].data.heading`}
                error={formik.touched.heading && formik.errors.heading}
                helperText={formik.touched.heading && formik.errors.heading}
                onChange={formik.handleChange}
                value={formik.values.sections[index]?.data.heading || ''}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                size="small"
                label="Description"
                variant="outlined"
                name={`sections[${index}].data.description`}
                error={formik.touched.description && formik.errors.description}
                helperText={formik.touched.description && formik.errors.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sections[index]?.data.description || ''}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                fullWidth
                label="Button Text"
                type="text"
                variant="outlined"
                size="small"
                name={`sections[${index}].data.buttonText`}
                error={formik.touched.buttonText && formik.errors.buttonText}
                helperText={formik.touched.buttonText && formik.errors.buttonText}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.sections[index]?.data.buttonText || ''}
              />
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                options={allCapabilities.filter((c) => {
                  return !activeCapabilities.find((ac) => ac._id === c._id);
                })}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleAutoCompleteChange(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Capabilities Here"
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={8}>
              <ActiveCapabilitiesTable
                activeCapabilities={activeCapabilities}
                setActiveCapabilities={setActiveCapabilities}
              />
            </Grid>
            <Grid item md={4}>
              <AllCapabilities
                allCapabilities={allCapabilities}
                activeCapabilities={activeCapabilities}
                setActiveCapabilities={setActiveCapabilities}
              />
            </Grid>
          </Grid>
        </Container>
      </ModuleLayout>
    </>
  );
};

export default OurCapabilities;
