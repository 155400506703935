import React, {useState, useEffect} from 'react';
import {
  Grid,
  Modal,
  TextField,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Button,
  ButtonGroup,
  Backdrop,
  Box
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {X as DeleteIcon} from 'react-feather';
import {checkImageWidthAndHeight} from 'src/utils/imageHelpers';
import pageConfig from '../../landingpage/config/index';
import toaster from '../../../utils/Toaster/Toaster';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const SubSections = (props) => {
  const classes = useStyles();
  const {toggleModal, getList, fieldName, isOpen, handleUpdateButton, updating} =
    props;
  const [subSectionState, setSubSectionState] = useState({
    isUpdating: false,
    subSection: {
      subTitle: '',
      description: '',
      image: null,
      arrow: null
    },
    error: {
      subTitle: '',
      description: '',
      image: null,
      arrow: null
    }
  });
  const validator = (type) => {
    const {subSection} = subSectionState;
    const error = {
      ...subSectionState.error
    };
    let hasError = false;

    Object.entries(subSection).forEach(([key, value]) => {
      if (!value) {
        error[key] = error[key] || 'Field can not be blank';
        hasError = true;
      }
    });
    Object.entries(error).forEach(([key, value]) => {
      if (value) {
        error[key];
        hasError = true;
      }
    });
    if (!hasError) {
      getList(subSection, type, fieldName);
    } else {
      setSubSectionState((prevState) => {
        return {
          ...prevState,
          error: {...error}
        };
      });
    }
    return hasError;
  };
  const handleSubSectionSave = (type) => {
    if (type === 'cancel') {
      handleUpdateButton();
      toggleModal();
      setSubSectionState((prevState) => {
        // console.log(subSectionState)
        return {
          ...prevState,
          isUpdating: false,
          subSection: {
            subTitle: '',
            description: '',
            image: null,
            arrow: null
          },
          error: {
            subTitle: '',
            description: '',
            //  image: null,
            arrow: null
          }
        };
      });
    } else if (type === 'save' || type === 'update') {
      const hasError = validator(type);
      if (!hasError) {
        toggleModal();
        // handleClose();
        setSubSectionState((prevState) => {
          return {
            ...prevState,
            isUpdating: false,
            subSection: {
              subTitle: '',
              description: '',
              image: null,
              arrow: null
            }
          };
        });
      } else {
        toaster('Something went wrong', 'error');
      }
    }
  };
  const handleOnChange = (event, field) => {
    const {value} = event.target;
    const {subSection, error} = subSectionState;

    if (value && value.length > pageConfig[field]) {
      setSubSectionState((prevState) => {
        return {
          ...prevState,
          error: {
            ...error,
            [field]: pageConfig.errorHelpText[field]
          }
        };
      });
      return;
    }

    setSubSectionState((prevState) => {
      return {
        ...prevState,
        subSection: {
          ...subSection,
          [field]: value
        },
        error: {
          ...error,
          [field]: ''
        }
      };
    });
  };
  const fileSelectHandler = (event, fieldTitle) => {
    const allFiles = event.target.files;
    // console.log(event.target.files);
    const imageConfig = pageConfig.subSection[fieldTitle];
    if (allFiles.length) {
      checkImageWidthAndHeight(allFiles, imageConfig)
        .then(() => {
          setSubSectionState((prevState) => {
            return {
              ...prevState,
              subSection: {
                ...prevState.subSection,
                [fieldTitle]: allFiles && allFiles[0]
              },
              errors: {
                ...prevState.error,
                [fieldTitle]: null
              }
            };
          });
        })
        .catch(() => {
          setSubSectionState((prevState) => {
            return {
              ...prevState,
              errors: {
                ...prevState.error,
                [fieldTitle]: pageConfig.errorHelpText[fieldTitle]
              }
            };
          });
        });
    }
  };
  useEffect(() => {
    if (updating) {
      setSubSectionState((prevState) => {
        return {
          ...prevState,
          subSection: updating,
          isUpdating: true
        };
      });
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        className={classes.modal}
        onClose={() => toggleModal()}
        closeAfterTransition
        BackdropProps={{
          timeout: 300
        }}
      >
        <Card sx={{width: '60em'}}>
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={() => toggleModal()}>
                <DeleteIcon size="20" color="#dc143c" />
              </IconButton>
            }
            title={`${
              subSectionState.isUpdating ? 'Update' : 'Save'
            } a Subsection Data`}
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Title"
                  size="small"
                  style={{marginTop: '10px'}}
                  error={subSectionState.error.subTitle ? true : false}
                  value={subSectionState.subSection.subTitle}
                  onChange={(event) => handleOnChange(event, 'subTitle')}
                  helperText={subSectionState.error.subTitle}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={subSectionState.error.description ? true : false}
                  label="Description"
                  value={subSectionState.subSection.description}
                  style={{margin: 0}}
                  onChange={(event) => handleOnChange(event, 'description')}
                  helperText={subSectionState.error.description}
                  fullWidth
                  // margin="normal"
                  multiline
                  rows={5}
                  required
                  variant="outlined"
                  InputLabelProps={{
                    // shrink: true,
                    style: {}
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  height={70}
                  borderRadius={1}
                  overflow="hidden"
                  clone={subSectionState.subSection.image}
                >
                  {!!subSectionState.subSection.image && (
                    <img
                      style={{
                        height: 'inherit',
                        width: 'inherit',
                        objectFit: 'cover'
                      }}
                      src={
                        subSectionState.subSection.image instanceof File
                          ? window.URL.createObjectURL(
                              subSectionState.subSection.image
                            )
                          : subSectionState.subSection.image
                      }
                      alt="safeIcon"
                    />
                  )}
                </Box>
                Selelct Image For Card : &nbsp;
                <text style={{color: 'red'}}>( Width: 50, Height: 50 )</text>
                <input
                  type="file"
                  name="SubSectionIcon"
                  onChange={(event) => fileSelectHandler(event, 'image')}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions disableSpacing sx={{justifyContent: 'flex-end'}}>
            <ButtonGroup
              variant="contained"
              aria-label="contained primary button group"
            >
              <Button
                color="secondary"
                onClick={() => handleSubSectionSave('cancel')}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  handleSubSectionSave(
                    subSectionState.isUpdating ? 'update' : 'save'
                  )
                }
              >
                {subSectionState.isUpdating ? 'Update' : 'Save'}
              </Button>
            </ButtonGroup>
          </CardActions>
        </Card>
      </Modal>
      {/* using MAterial Ui */}
    </>
  );
};
export default SubSections;
