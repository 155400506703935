import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Tooltip
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_CURRENT_USER} from 'src/redux/actions';
import Logo from './Logo';

const DashboardNavbar = ({onMobileNavOpen, ...rest}) => {
  const {currentUser} = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleSingOut = () => {
    AxiosServices.get(ApiServices.logout)
      .then(() => {
        dispatch({type: ADD_CURRENT_USER, user: null});
        toast.success('Logout Successful');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to={currentUser ? '/dashboard' : '/'}>
          <Logo />
        </RouterLink>
        <Box sx={{flexGrow: 1, justifyContent: 'space-around'}} />
        <Hidden lgDown>
          <Tooltip title="Sign Out">
            <IconButton color="inherit" onClick={() => handleSingOut()}>
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
