import React, {useState} from 'react';
import {
  Container,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import {Mail as MailIcon} from 'react-feather';
import AddMail from './AddMail';
import MailList from './MailList';
import ViewTemplate from './ViewTemplate';

const MailSettingsViewer = () => {
  const [open, setOpen] = useState({s: false, type: 'hire_dev'});
  const handleViewMailClick = (type) => {
    setOpen(() => {
      return {s: true, type};
    });
  };

  const handleClose = () => {
    setOpen((prevState) => {
      return {...prevState, s: false};
    });
  };

  return (
    <Container maxWidth="lg">
      <Grid container alignItems="center" spacing={1}>
        <Grid item md={11} xs={11}>
          <Typography component="h4" variant="h4">
            Used for Hire Developer Leads
          </Typography>
        </Grid>
        <Grid item md={1} xs={1}>
          <Tooltip title="View Mail Template" placement="top">
            <IconButton
              color="inherit"
              onClick={() => handleViewMailClick('hire_dev')}
            >
              <MailIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider sx={{mb: '5px'}} />
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <AddMail type="hire_developer" />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <MailList type="hire_developer" />
        </Grid>
      </Grid>
      <Grid container alignItems="center" sx={{mt: '10px'}} spacing={1}>
        <Grid item md={11} xs={11}>
          <Typography component="h4" variant="h4">
            Used for Partner Request
          </Typography>
        </Grid>
        <Grid item md={1} xs={1}>
          <Tooltip title="View Mail Template" placement="top">
            <IconButton
              color="inherit"
              onClick={() => handleViewMailClick('become_partner')}
            >
              <MailIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider sx={{mb: '5px'}} />
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <AddMail type="become_partner" />
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <MailList type="become_partner" />
        </Grid>
      </Grid>
      <ViewTemplate open={open.s} type={open.type} handleClose={handleClose} />
    </Container>
  );
};

export default MailSettingsViewer;
