import {Button, Grid, Container, Box, TextField} from '@material-ui/core';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import ApiServices from 'src/networks/ApiServices';
import AxiosServices from 'src/networks/AxiosService';
import buildFormData, {generateImageUrl} from 'src/networks/utils';
import {ADD_ALL_CLIENTS} from 'src/redux/actions';
import toaster from 'src/utils/Toaster/Toaster';
import * as Yup from 'yup';

import ModuleLayout from '../ModuleLayout';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  logo: Yup.string().required(),
  active: Yup.string().required()
});

const CreateOrUpdateClients = ({isUpdate, setOptionViewer}) => {
  const {clients} = useSelector((state) => state);
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    clientName: '',
    logo: null,
    active: true
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        buildFormData(formData, values);

        const response = isUpdate
          ? AxiosServices.put(ApiServices.updateClient(isUpdate), formData, false)
          : AxiosServices.post(ApiServices.createClient, formData, false, true);
        toaster(
          `${isUpdate ? 'Updated' : 'Created'} Sucessfully`,
          'success',
          response.data
        );
        response.then(({data: d}) => {
          dispatch({
            type: ADD_ALL_CLIENTS,
            clients: isUpdate
              ? clients.map((c) => (c._id === isUpdate ? d : c))
              : [...clients, d]
          });
          setOptionViewer({id: 'OurClientsPageViewer', isUpdating: ''});
        });
      } catch (err) {
        toaster(err.response.data.error, 'error', {});
      } finally {
        formik.setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (isUpdate) {
      const [data] = clients.filter((c) => c._id === isUpdate);
      if (!!data) {
        setInitialValues(data);
      }
    }
  }, [isUpdate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet>
        <title>{isUpdate ? `Update` : `Create`} Clients | BJIT Hire Developer</title>
      </Helmet>
      <ModuleLayout
        moduleTitle={isUpdate ? `Update Clients` : `Create Clients`}
        disabledCrossButton
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                fullWidth
                type="text"
                label="Name"
                variant="outlined"
                size="small"
                name="name"
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name || ''}
              />
            </Grid>
            <Grid item md={6}>
              <Box
                height={180}
                borderRadius={1}
                overflow="hidden"
                clone={formik.values.logo}
              >
                {!!formik.values.logo && (
                  <img
                    style={{
                      height: 'inherit',
                      width: 'inherit',
                      objectFit: 'cover'
                    }}
                    src={
                      formik.values.logo instanceof File
                        ? window.URL.createObjectURL(formik.values.logo)
                        : generateImageUrl(formik.values.logo)
                    }
                    alt="client-logo"
                  />
                )}
              </Box>
              Select Cover Image: &nbsp;
              <text style={{color: 'red'}}>( Width: 165, Height: 72 )</text>
              <input
                type="file"
                name="logo"
                onChange={(e) => formik.setFieldValue('logo', e.target.files[0])}
              />
            </Grid>
          </Grid>
        </Container>
      </ModuleLayout>
      <Box textAlign="center" sx={{py: 2}}>
        <Button
          disabled={!formik.isValid || formik.isSubmitting}
          style={{width: '300px'}}
          variant="contained"
          color="primary"
          type="submit"
          size="medium"
        >
          {isUpdate ? `Update` : `Create`}
        </Button>
      </Box>
    </form>
  );
};

export default CreateOrUpdateClients;
