import React, {useEffect, useState} from 'react';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  CardContent
} from '@material-ui/core';
import {X as DeleteIcon} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import {generateImageUrl} from 'src/networks/utils';
import {nanoid} from 'nanoid';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {addUsers} from 'src/redux/actions';
import {toast} from 'react-toastify';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal/DeleteConfirmationModal';

const columns = [
  {
    id: 'picture',
    label: 'Picture',
    align: 'center'
  },
  {
    id: 'fullName',
    label: 'Name',
    align: 'center'
  },
  {
    id: 'username',
    label: 'User Name',
    align: 'center'
  },
  {
    id: 'role',
    label: 'Role',
    align: 'center'
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'center',
    maxWidth: '200px'
  }
];

const AccoutLists = () => {
  const {
    users,
    currentUser: {
      user: {_id: currentUserId}
    }
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectForDelete, setSelectForDelete] = useState('');

  const handleClickOpen = (id) => {
    setSelectForDelete(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const deleteHandler = () => {
    AxiosServices.remove(ApiServices.removeUser(selectForDelete))
      .then(() => {
        const newDoc = users.filter((u) => u._id !== selectForDelete);
        dispatch(addUsers(newDoc));
        setPage(newDoc.length % limit >= 0 ? page : page - 1);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        handleClose();
      });
  };

  const createData = (id, picture, fullName, username, role) => {
    const pictureBox = (
      <Box overflow="hidden" clone>
        <img
          key={Date.now()}
          height={60}
          width={60}
          style={{objectFit: 'cover', borderRadius: '50%'}}
          src={generateImageUrl(picture)}
          alt={picture}
        />
      </Box>
    );

    const action = (
      <>
        <Tooltip title="Delete" disabled={id === currentUserId}>
          <IconButton onClick={() => handleClickOpen(id)}>
            <DeleteIcon size="20" color="#dc143c" />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      _id: id,
      picture: pictureBox,
      fullName,
      username,
      role,
      action
    };
  };

  useEffect(() => {
    const dataRows = [];
    users &&
      users.forEach((d) => {
        dataRows.push(createData(d._id, d.picture, d.fullName, d.username, d.role));
      });
    setRows(dataRows);
  }, [limit, page, users]);

  return (
    <>
      <Card sx={{p: 0}}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{maxWidth: column.maxWidth}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                <>
                  {rows.slice(limit * page, limit * page + limit).map((row) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={nanoid()}
                        selected={row._id === currentUserId}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              <TableCell key={nanoid()} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <>
                  <TableRow hover>
                    <TableCell align="center" colSpan={8}>
                      <h4>Nothing to show</h4>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={rows.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardContent>
      </Card>
      <DeleteConfirmationModal
        deleteHandler={deleteHandler}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export default AccoutLists;
