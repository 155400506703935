import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import ReviewListToolbar from './ReviewListToolbar';
import ReviewsListResults from './ReviewsListResults';

const ReviewList = (props) => {
  const {reviews} = useSelector((state) => state);
  const {setOptionViewer} = props;
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    setSearchResult(
      reviews.filter(({clientName}) =>
        clientName?.name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, reviews]);

  return (
    <>
      <ReviewListToolbar
        setOptionViewer={setOptionViewer}
        handleSearchTable={setSearchText}
      />
      <ReviewsListResults
        searchResult={searchResult}
        setSearchResult={setSearchResult}
        setOptionViewer={setOptionViewer}
      />
    </>
  );
};

export default ReviewList;
