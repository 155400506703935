import {useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import buildFormData from 'src/networks/utils';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {addUsers} from 'src/redux/actions';

const roles = [
  {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'sales',
    label: 'Sales'
  },
  {
    value: 'hr',
    label: 'HR'
  }
];

const AccountProfileDetails = (props) => {
  const {users} = useSelector((state) => state);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    fullName: '',
    username: '',
    password: '',
    confirmPassword: '',
    role: 'admin',
    picture: null
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const fileSelectHandler = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setValues({
      ...values,
      picture: file
    });
    event.target.value = null;
  };

  const validatePassword = () => {
    if (
      values.password.includes(values.confirmPassword) &&
      values.password.length > 7
    )
      return true;
    return false;
  };

  const validateForm = () => {
    const {password, confirmPassword, ...others} = values;
    return Object.keys(others).some((k) => others[k] === '' || others[k] === null);
  };

  const handleNewUserCreate = (e) => {
    e.preventDefault();
    if (validatePassword() && !validateForm()) {
      const {confirmPassword, ...others} = values;
      const formData = new FormData();
      buildFormData(formData, others);
      AxiosServices.post(ApiServices.createUser, formData, false, true)
        .then(({data}) => {
          dispatch(addUsers([data, ...users]));
        })
        .catch((err) => {
          toast.error(err.message);
        })
        .finally(() => {
          setValues({
            fullName: '',
            username: '',
            password: '',
            confirmPassword: '',
            role: 'admin',
            picture: null
          });
        });
    }
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleNewUserCreate} {...props}>
      <Card>
        <CardHeader title="Profile Creation" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Full name"
                size="small"
                name="fullName"
                onChange={handleChange}
                required
                value={values.fullName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                size="small"
                onChange={handleChange}
                required
                value={values.username}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                required
                label="Password"
                name="password"
                size="small"
                onChange={handleChange}
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                size="small"
                name="confirmPassword"
                onChange={handleChange}
                required
                value={values.confirmPassword}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Box
                height={180}
                borderRadius={1}
                overflow="hidden"
                clone={values.picture}
              >
                {!!values?.picture && (
                  <img
                    style={{
                      height: 'inherit',
                      width: 'inherit',
                      objectFit: 'cover'
                    }}
                    src={window.URL.createObjectURL(values.picture)}
                    alt="profile"
                  />
                )}
              </Box>
              Select Profile Picture: &nbsp;
              <input
                type="file"
                name="picture"
                onChange={(event) => fileSelectHandler(event)}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Select Role"
                name="role"
                onChange={handleChange}
                required
                size="small"
                select
                SelectProps={{native: true}}
                value={values.role}
                variant="outlined"
              >
                {roles.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button type="submit" color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default AccountProfileDetails;
