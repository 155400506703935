import {
  Button,
  Grid,
  Container,
  Box,
  TextField,
  Autocomplete
} from '@material-ui/core';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import ApiServices from 'src/networks/ApiServices';
import AxiosServices from 'src/networks/AxiosService';
import buildFormData, {generateImageUrl} from 'src/networks/utils';
import {ADD_ALL_REVIEWS} from 'src/redux/actions';
import toaster from 'src/utils/Toaster/Toaster';
import * as Yup from 'yup';
import ModuleLayout from '../ModuleLayout';

const schema = Yup.object().shape({
  clientName: Yup.string().required(),
  authorName: Yup.string().required(),
  authorDesignation: Yup.string().required(),
  authorImage: Yup.string().required(),
  title: Yup.string().required(),
  body: Yup.string().required(),
  active: Yup.string().required()
});

const CreateOrUpdateReview = ({isUpdate, ...props}) => {
  const {reviews, clients} = useSelector((state) => state);
  const dispatch = useDispatch();

  const {
    data: {setOptionViewer}
  } = props;

  const [initialValues, setInitialValues] = useState({
    clientName: '',
    authorName: '',
    authorDesignation: '',
    authorImage: new File([''], ''),
    title: '',
    body: '',
    active: true
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const newValues = {...values, clientName: values.clientName._id};
        const formData = new FormData();
        buildFormData(formData, newValues);

        const response = isUpdate
          ? AxiosServices.put(ApiServices.updateReview(isUpdate), formData, false)
          : AxiosServices.post(ApiServices.createReview, formData, false, true);
        toaster(
          `${isUpdate ? 'Updated' : 'Created'} Sucessfully`,
          'success',
          response.data
        );
        response.then(({data: d}) => {
          dispatch({
            type: ADD_ALL_REVIEWS,
            reviews: isUpdate
              ? reviews.map((r) => (r._id === isUpdate ? d : r))
              : [...reviews, d]
          });
          setOptionViewer((prevState) => {
            const newState = {...prevState, id: 'OurReviewsPageViewer'};
            return newState;
          });
        });
      } catch (err) {
        toaster(err.response.data.error, 'error', {});
      } finally {
        formik.setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    const [data] = reviews.filter((r) => r._id === isUpdate);
    if (!!data) {
      setInitialValues(data);
    }
  }, [isUpdate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Helmet>
        <title>{isUpdate ? `Update` : `Create`} Reviews | BJIT Hire Developer</title>
      </Helmet>
      <ModuleLayout
        moduleTitle={isUpdate ? `Update Review` : `Create Review`}
        disabledCrossButton
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Autocomplete
                options={clients.filter((c) => c.active)}
                value={formik.values?.clientName || null}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => formik.setFieldValue('clientName', v || null)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                type="text"
                label="Author Name"
                variant="outlined"
                size="small"
                name="authorName"
                error={Boolean(
                  formik.touched.authorName && formik.errors.authorName
                )}
                helperText={formik.touched.authorName && formik.errors.authorName}
                onChange={formik.handleChange}
                value={formik.values?.authorName || ''}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                type="text"
                label="Author Designation"
                variant="outlined"
                size="small"
                name="authorDesignation"
                error={Boolean(
                  formik.touched.authorDesignation && formik.errors.authorDesignation
                )}
                helperText={
                  formik.touched.authorDesignation && formik.errors.authorDesignation
                }
                onChange={formik.handleChange}
                value={formik.values?.authorDesignation || ''}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                type="text"
                label="Title"
                variant="outlined"
                size="small"
                name="title"
                error={Boolean(formik.touched.title && formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                onChange={formik.handleChange}
                value={formik.values?.title || ''}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                fullWidth
                type="text"
                label="Review"
                variant="outlined"
                size="small"
                name="body"
                multiline
                rows={3}
                error={Boolean(formik.touched.body && formik.errors.body)}
                helperText={formik.touched.body && formik.errors.body}
                onChange={formik.handleChange}
                value={formik.values?.body || ''}
              />
            </Grid>
            <Grid item md={6}>
              <Box
                height={90}
                borderRadius={1}
                overflow="hidden"
                clone={formik.values?.authorImage}
              >
                {!!formik.values?.authorImage && (
                  <img
                    style={{
                      height: 'inherit',
                      width: 'inherit',
                      objectFit: 'cover'
                    }}
                    src={
                      formik.values?.authorImage instanceof File
                        ? window.URL.createObjectURL(formik.values.authorImage)
                        : generateImageUrl(formik.values.authorImage)
                    }
                    alt="author-logo"
                  />
                )}
              </Box>
              Upload Author Image: &nbsp;
              <text style={{color: 'red'}}>( Width: 56, Height: 56 )</text>
              <input
                type="file"
                name="authorImage"
                onChange={(e) =>
                  formik.setFieldValue('authorImage', e.target.files[0])
                }
              />
            </Grid>
          </Grid>
        </Container>
      </ModuleLayout>
      <Box textAlign="center" sx={{py: 2}}>
        <Button
          disabled={!formik.isValid || formik.isSubmitting}
          style={{width: '300px'}}
          variant="contained"
          color="primary"
          type="submit"
          size="medium"
        >
          {isUpdate ? `Update` : `Create`}
        </Button>
      </Box>
    </form>
  );
};

export default CreateOrUpdateReview;
