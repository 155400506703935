import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React from 'react';

const ViewTemplate = ({handleClose, open, type}) => {
  return (
    <Dialog open={open} onClose={handleClose} scroll="paper">
      <DialogTitle>
        <h2>
          {type === 'hire_dev'
            ? 'Hire Developer Template'
            : 'Become Partner Template'}
        </h2>
      </DialogTitle>
      <DialogContent dividers>
        <div
          dangerouslySetInnerHTML={{
            __html:
              type === 'hire_dev' ? '<h1>Hire Dev</h1>' : '<h1>Become Partner</h1>'
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewTemplate;
