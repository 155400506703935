import {Navigate} from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import FrontPage from 'src/pages/FrontPage';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import Settings from 'src/pages/Settings';
import OurClients from './pages/OurClients';
import ClientsReviews from './pages/ClientsReviews';
import MailingSettings from './pages/MailingSettings';
import HiringList from './pages/HiringList';
import PartnerRequest from './pages/PartnerRequest';

const routes = (currentUser) => [
  {
    path: 'dashboard',
    element: currentUser ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      {path: '/', element: <Dashboard />},
      {path: 'account', element: <Account />},
      {path: 'landingpage', element: <FrontPage />},
      {path: 'clients', element: <OurClients />},
      {path: 'reviews', element: <ClientsReviews />},
      {path: 'hiring-list', element: <HiringList />},
      {
        path: 'partners',
        element: <PartnerRequest />,
        children: [{path: 'settings', element: <PartnerRequest />}]
      },
      {path: 'mailing-settings', element: <MailingSettings />},
      {path: 'admins', element: <Settings />},
      {path: '*', element: <Navigate to="/404" />}
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {path: '/', element: <Navigate to="/login" />},
      {path: 'home', element: <Navigate to="/login" />},
      {path: 'Home', element: <Navigate to="/login" />},
      {path: 'HOME', element: <Navigate to="/login" />},
      {path: 'login', element: <Login />},
      {path: '404', element: <NotFound />},
      {path: '*', element: <Navigate to="/404" />}
    ]
  }
];

export default routes;
