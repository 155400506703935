import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {BookOpen as ReadIcon, Book as UnreadIcon} from 'react-feather';
import {useDispatch, useSelector} from 'react-redux';
import './style.css';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {addPartners} from 'src/redux/actions';
import {toast} from 'react-toastify';

const ViewPartnerDetails = ({handleClose, open, id}) => {
  const {partners} = useSelector((state) => state);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});

  const handleReadUnreadClick = (docId, status = 'read') => {
    AxiosServices.put(ApiServices.updatePartnerRequest(docId), {status})
      .then(({data}) => {
        const newDoc = partners.map((p) => (p._id === docId ? data : p));
        dispatch(addPartners(newDoc));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (id) {
      const [selectedLead] = partners.filter((l) => l._id === id);
      setDetails(selectedLead);
    }
  }, [id, partners]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          <h2>Details Information of {details?.company_information?.name}</h2>
        </DialogTitle>
        <DialogContent dividers>
          <h3 className="partnerTitle">Contact Information</h3>
          <table width="100%" className="partnerTable" border={1}>
            <tr>
              <td className="tableTitle">Name</td>
              <td>{details?.contact_information?.name}</td>
            </tr>
            <tr>
              <td className="tableTitle">Phones</td>
              <td>{details?.contact_information?.phone}</td>
            </tr>
            <tr>
              <td className="tableTitle">Email</td>
              <td>{details?.contact_information?.email}</td>
            </tr>
            <tr>
              <td className="tableTitle">Working Email</td>
              <td>{details?.contact_information?.work_email}</td>
            </tr>
            <tr>
              <td className="tableTitle">Department</td>
              <td>{details?.contact_information?.department}</td>
            </tr>
            <tr>
              <td className="tableTitle">Job Title</td>
              <td>{details?.contact_information?.job_title}</td>
            </tr>
          </table>
          <h3 className="partnerTitle">Company Information</h3>
          <table width="100%" className="partnerTable" border={1}>
            <tr>
              <td className="tableTitle">Name</td>
              <td>{details?.company_information?.name}</td>
            </tr>
            <tr>
              <td className="tableTitle">Country</td>
              <td>{details?.company_information?.country}</td>
            </tr>
            <tr>
              <td className="tableTitle">State</td>
              <td>{details?.company_information?.state}</td>
            </tr>
            <tr>
              <td className="tableTitle">City</td>
              <td>{details?.company_information?.city}</td>
            </tr>
            <tr>
              <td className="tableTitle">Post Code</td>
              <td>{details?.company_information?.post_code}</td>
            </tr>
            <tr>
              <td className="tableTitle">Website</td>
              <td>{details?.company_information?.website}</td>
            </tr>
            <tr>
              <td className="tableTitle">Annual Revenue</td>
              <td>{details?.company_information?.annual_revenue}</td>
            </tr>
            <tr>
              <td className="tableTitle">Number of Employees</td>
              <td>{details?.company_information?.no_of_employee}</td>
            </tr>
          </table>
          <h3 className="partnerTitle">Partnership Information</h3>
          <p>
            <span className="tableTitle">Solution And Service : </span>
            {details?.partnership_information?.solution_and_services}
          </p>
          <p>
            <span className="tableTitle">Expectations from Us : </span>
            {details?.partnership_information?.expectations_from_us}
          </p>
          <p>
            <span className="tableTitle">Additional Information : </span>
            {details?.partnership_information?.additional_information}
          </p>
        </DialogContent>
        <DialogActions>
          <Tooltip
            enterDelay={300}
            title={details.status === 'read' ? 'Unread' : 'Read'}
            placement="top"
          >
            <IconButton
              onClick={() =>
                handleReadUnreadClick(
                  details._id,
                  details.status === 'read' ? 'unread' : 'read'
                )
              }
            >
              {details.status === 'read' ? (
                <ReadIcon size="20" />
              ) : (
                <UnreadIcon size="20" />
              )}
            </IconButton>
          </Tooltip>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewPartnerDetails;
