import {Helmet} from 'react-helmet';
import {Box, Container, Grid} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import Leads from 'src/components/dashboard/Leads';
import TasksProgress from 'src/components/dashboard//TasksProgress';
import PartnerRequests from 'src/components/dashboard/PartnerRequests';
import TotalProfit from 'src/components/dashboard//TotalProfit';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {toast} from 'react-toastify';
import {
  addClients,
  addLeads,
  addMails,
  addPartners,
  addReviews,
  addUsers,
  setRefresh,
  addCurrentUser
} from 'src/redux/actions';
import getMember from 'src/utils/jwtSingoutHelper';

const Dashboard = () => {
  const {refresh} = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([
      AxiosServices.get(ApiServices.getAllClients),
      AxiosServices.get(ApiServices.getAllReviews),
      AxiosServices.get(ApiServices.getAllLeads),
      AxiosServices.get(ApiServices.getAllMails),
      AxiosServices.get(ApiServices.getAllPartners),
      AxiosServices.get(ApiServices.allUsers)
    ])
      .then(([clients, reviews, leads, mails, partners, users]) => {
        dispatch(addClients(clients.data));
        dispatch(addReviews(reviews.data));
        dispatch(addLeads(leads.data));
        dispatch(addMails(mails.data));
        dispatch(addPartners(partners.data));
        dispatch(addUsers(users.data));
      })
      .catch(() => {
        toast.error('Data From Backend not received');
      })
      .finally(() => dispatch(setRefresh(false)));
  }, [refresh]);

  return (
    <>
      <Helmet>
        <title>Dashboard | BJIT Hire Developer</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Leads />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <PartnerRequests />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TasksProgress />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProfit sx={{height: '100%'}} />
            </Grid>
            {/* <Grid item lg={8} md={12} xl={9} xs={12}>
            <Sales />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <TrafficByDevice sx={{height: '100%'}} />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <LatestProducts sx={{height: '100%'}} />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <LatestOrders />
          </Grid> */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
