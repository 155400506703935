import React, {useEffect, useState} from 'react';
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  CardContent
} from '@material-ui/core';
import {nanoid} from 'nanoid';
import {useDispatch, useSelector} from 'react-redux';
import {X as DeleteIcon} from 'react-feather';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {toast} from 'react-toastify';
import {addMails} from 'src/redux/actions';
import DeleteConfirmationHireRequest from '../common/DeleteConfirmationHireRequest/DeleteConfirmationHireRequest';

const columns = [
  {
    id: 'email',
    label: 'Email',
    align: 'center'
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'center',
    maxWidth: '200px'
  }
];

const MailList = ({type}) => {
  const {emailList} = useSelector((state) => state);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [openConfirmMessage, setOpenConfirmMessage] = useState(false);
  const [selectForDelete, setSelectForDelete] = useState('');

  const handleClickOpen = (id) => {
    setSelectForDelete(id);
    setOpenConfirmMessage(true);
  };

  const handleCloseMessage = () => {
    setOpenConfirmMessage(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const deleteHandler = () => {
    AxiosServices.remove(ApiServices.deleteMail(selectForDelete))
      .then(() => {
        const newDoc = emailList.filter((eL) => eL._id !== selectForDelete);
        dispatch(addMails(newDoc));
        setPage(newDoc.length % limit >= 0 ? page : page - 1);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        handleCloseMessage();
      });
  };

  const createData = (_id, email) => {
    const action = (
      <>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleClickOpen(_id)}>
            <DeleteIcon size="20" color="#dc143c" />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      _id,
      email,
      action
    };
  };

  useEffect(() => {
    const dataRows = [];
    emailList &&
      emailList
        .filter((e) => e.category === type)
        .forEach((d) => {
          dataRows.push(createData(d._id, d.email));
        });
    setRows(dataRows);
  }, [limit, page, emailList, type]);

  return (
    <>
      <Card>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{maxWidth: column.maxWidth}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                <>
                  {rows.slice(limit * page, limit * page + limit).map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={nanoid()}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              <TableCell key={nanoid()} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <>
                  <TableRow hover>
                    <TableCell align="center" colSpan={8}>
                      <h4>Nothing to show</h4>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={rows.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardContent>
      </Card>
      <DeleteConfirmationHireRequest
        deleteHandler={deleteHandler}
        handleClose={handleCloseMessage}
        open={openConfirmMessage}
      />
    </>
  );
};

export default MailList;
