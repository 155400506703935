import {
  Grid,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import {Search as SearchIcon} from 'react-feather';

const ReviewListToolbar = (props) => {
  const {setOptionViewer, handleSearchTable = (v) => console.log(v)} = props;
  return (
    <Card sx={{mt: 3}}>
      <CardContent>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item md={6}>
            <TextField
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search here..."
              onChange={(e) => handleSearchTable(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                setOptionViewer({
                  id: 'CreateOrUpdateReview',
                  isUpdating: ''
                })
              }
            >
              Add New Review
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReviewListToolbar;
