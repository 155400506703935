import {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Card,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import {nanoid} from 'nanoid';
import AxiosServices from 'src/networks/AxiosService';
import ApiServices from 'src/networks/ApiServices';
import {toast} from 'react-toastify';
import {generateImageUrl} from 'src/networks/utils';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_ALL_REVIEWS} from 'src/redux/actions';
import Truncate from '../helper/Truncate';
import DeleteConfirmationHireRequest from '../common/DeleteConfirmationHireRequest/DeleteConfirmationHireRequest';

const columns = [
  {
    id: 'name',
    label: 'Client',
    align: 'center'
  },
  {
    id: 'authorName',
    label: 'Author Name',
    align: 'center'
  },
  {
    id: 'authorDesignation',
    label: 'Author Designation',
    align: 'center'
  },
  {
    id: 'authorImage',
    label: 'Author Image',
    align: 'center'
  },
  {
    id: 'title',
    label: 'Topic',
    align: 'center'
  },
  {
    id: 'body',
    label: 'Review',
    align: 'center'
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'center',
    maxWidth: '200px'
  },
  {
    id: 'active',
    label: 'Active',
    align: 'center',
    maxWidth: '200px'
  }
];

const ReviewsListResults = ({
  searchResult,
  setSearchResult,
  setOptionViewer,
  ...rest
}) => {
  const {reviews} = useSelector((state) => state);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [openConfirmMessage, setOpenConfirmMessage] = useState(false);
  const [selectForDelete, setSelectForDelete] = useState('');

  const handleClickOpen = (id) => {
    setSelectForDelete(id);
    setOpenConfirmMessage(true);
  };

  const handleCloseMessage = () => {
    setOpenConfirmMessage(false);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const deleteHandler = () => {
    AxiosServices.remove(ApiServices.removeReview(selectForDelete))
      .then(() => {
        const newDoc = reviews.filter((r) => r._id !== selectForDelete);
        dispatch({type: ADD_ALL_REVIEWS, reviews: newDoc});
        setPage(newDoc.length % limit > 0 ? page : page - 1);
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        handleCloseMessage();
      });
  };

  const handleActiveStatusChange = (id, activeState) => {
    AxiosServices.put(ApiServices.updateActiveStatus('reviews', id), {
      active: !activeState
    })
      .then(({data}) => {
        const newDoc = reviews.map((r) => (r._id === id ? data : r));
        dispatch({type: ADD_ALL_REVIEWS, reviews: newDoc});
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const createData = (
    _id,
    client,
    reviewer,
    reviewerDesignation,
    reviewerImage,
    topic,
    review,
    active
  ) => {
    const reviewerBox = (
      <Box overflow="hidden" clone>
        <img
          key={Date.now()}
          height={60}
          width={60}
          style={{objectFit: 'cover', borderRadius: '50%'}}
          src={
            reviewerImage instanceof File
              ? window.URL.createObjectURL(reviewerImage)
              : generateImageUrl(reviewerImage)
          }
          alt={reviewer}
        />
      </Box>
    );
    const clientNameBox = (
      <Box overflow="hidden" clone>
        <img
          key={Date.now()}
          height={50}
          width={100}
          style={{objectFit: 'fill'}}
          src={
            client instanceof File
              ? window.URL.createObjectURL(client)
              : generateImageUrl(client)
          }
          alt="client"
        />
      </Box>
    );
    const action = (
      <>
        <Button
          onClick={() =>
            setOptionViewer({
              id: 'CreateOrUpdateReview',
              isUpdating: _id
            })
          }
          size="small"
          color="primary"
          variant="outlined"
        >
          Edit
        </Button>
        &nbsp;
        <Button
          onClick={() => handleClickOpen(_id)}
          size="small"
          color="secondary"
          variant="outlined"
        >
          Delete
        </Button>
      </>
    );

    const activeBox = (
      <>
        <Switch
          checked={active}
          onChange={() => handleActiveStatusChange(_id, active)}
        />
      </>
    );

    return {
      _id,
      name: clientNameBox,
      authorName: reviewer,
      authorDesignation: reviewerDesignation,
      authorImage: reviewerBox,
      title: Truncate(topic, 20),
      body: Truncate(review, 40),
      action,
      active: activeBox
    };
  };

  useEffect(() => {
    const dataRows = [];
    searchResult &&
      searchResult.forEach(({clientName, ...d}, pos) => {
        dataRows.push(
          createData(
            d._id,
            clientName.logo,
            d.authorName,
            d.authorDesignation,
            d.authorImage,
            d.title,
            d.body,
            d.active,
            pos
          )
        );
      });
    setRows(dataRows);
  }, [limit, page, searchResult]);

  return (
    <>
      <Card {...rest} sx={{mt: 1}}>
        <Box sx={{minWidth: 1050}}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{maxWidth: column.maxWidth}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                <>
                  {rows.slice(limit * page, limit * page + limit).map((row) => {
                    return (
                      <TableRow hover tabIndex={-1} key={nanoid()}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <>
                              <TableCell key={nanoid()} align={column.align}>
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <>
                  <TableRow hover>
                    <TableCell align="center" colSpan={8}>
                      <h4>Nothing to show</h4>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={rows.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <DeleteConfirmationHireRequest
        deleteHandler={deleteHandler}
        handleClose={handleCloseMessage}
        open={openConfirmMessage}
      />
    </>
  );
};

export default ReviewsListResults;
