import React, {useEffect, useState} from 'react';
import {
  Grid,
  Modal,
  TextField,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  CardActions,
  Button,
  ButtonGroup,
  Backdrop,
  Box
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {X as DeleteIcon} from 'react-feather';
import {checkImageWidthAndHeight} from 'src/utils/imageHelpers';
import pageConfig from '../../landingpage/config/index';
import toaster from '../../../utils/Toaster/Toaster';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const BulletPoints = (props) => {
  const classes = useStyles();
  const {toggleModal, getList, fieldName, isOpen, updating, handleUpdateButton} =
    props;
  const [bulletPointState, setBulletPointState] = useState({
    isUpdating: false,
    bulletPoint: {
      title: '',
      subTitle: '',
      image: null
    },
    errors: {
      title: '',
      subTitle: '',
      image: null
    }
  });

  const bulletPointDataValidator = (type) => {
    const {bulletPoint} = bulletPointState;
    const errors = {
      ...bulletPointState.errors
    };
    let hasError = false;

    Object.entries(bulletPoint).forEach(([key, value]) => {
      if (!value) {
        errors[key] = errors[key] || 'Field can not be blank';
        hasError = true;
      }
    });

    Object.entries(errors).forEach(([key, value]) => {
      if (value) {
        errors[key];
        hasError = true;
      }
    });

    if (!hasError) {
      getList(bulletPoint, type, fieldName);
    } else {
      setBulletPointState((prevState) => {
        return {
          ...prevState,
          errors: {...errors}
        };
      });
    }

    return hasError;
  };

  const handleBulletPointSave = (type) => {
    if (type === 'cancel') {
      handleUpdateButton();
      toggleModal();
      setBulletPointState((prevState) => {
        return {
          ...prevState,
          isUpdating: false,
          bulletPoint: {
            title: '',
            subTitle: '',
            image: null
          },
          errors: {
            title: '',
            subTitle: '',
            image: null
          }
        };
      });
    } else if (type === 'save' || type === 'update') {
      const hasError = bulletPointDataValidator(type);
      if (!hasError) {
        toggleModal();
        setBulletPointState((prevState) => {
          return {
            ...prevState,
            isUpdating: false,
            bulletPoint: {
              title: '',
              subTitle: '',
              image: null
            }
          };
        });
      } else {
        toaster('Something went wrong', 'error');
      }
    }
  };

  const handleOnChange = (event, field) => {
    const {value} = event.target;
    const {bulletPoint, errors} = bulletPointState;
    if (value && value.length > pageConfig[field]) {
      setBulletPointState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...errors,
            [field]: pageConfig.errorHelpText[field]
          }
        };
      });
      return;
    }
    setBulletPointState((prevState) => {
      return {
        ...prevState,
        bulletPoint: {
          ...bulletPoint,
          [field]: value
        },
        errors: {
          ...errors,
          [field]: ''
        }
      };
    });
  };

  const fileSelectHandler = (event, fieldTitle) => {
    const allFiles = event.target.files;
    const imageConfig = pageConfig.bulletPoint.image;
    if (allFiles.length) {
      checkImageWidthAndHeight(allFiles, imageConfig)
        .then(() => {
          setBulletPointState((prevState) => {
            return {
              ...prevState,
              bulletPoint: {
                ...prevState.bulletPoint,
                image: allFiles && allFiles[0]
              },
              errors: {
                ...prevState.errors,
                [fieldTitle]: null
              }
            };
          });
        })
        .catch(() => {
          setBulletPointState((prevState) => {
            return {
              ...prevState,
              errors: {
                ...prevState.errors,
                [fieldTitle]: pageConfig.errorHelpText[fieldTitle]
              }
            };
          });
        });
    }
  };

  useEffect(() => {
    if (updating) {
      setBulletPointState((prevState) => {
        return {
          ...prevState,
          bulletPoint: updating,
          isUpdating: true
        };
      });
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        className={classes.modal}
        onClose={() => toggleModal()}
        closeAfterTransition
        BackdropProps={{
          timeout: 300
        }}
      >
        <Card sx={{width: '60em'}}>
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={() => toggleModal()}>
                <DeleteIcon size="20" color="#dc143c" />
              </IconButton>
            }
            title={`${
              bulletPointState.isUpdating ? 'Update' : 'Create a new '
            } Bullet Point`}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Title"
                  size="small"
                  error={bulletPointState.errors.title ? true : false}
                  value={bulletPointState.bulletPoint.title}
                  onChange={(event) => handleOnChange(event, 'title')}
                  helperText={bulletPointState.errors.title}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={bulletPointState.errors.subTitle ? true : false}
                  label="Sub Title"
                  value={bulletPointState.bulletPoint.subTitle}
                  onChange={(event) => handleOnChange(event, 'subTitle')}
                  helperText={bulletPointState.errors.subTitle}
                  fullWidth
                  multiline
                  rows={3}
                  required
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item md={5}>
                <Box
                  height={180}
                  borderRadius={1}
                  overflow="hidden"
                  clone={bulletPointState.bulletPoint.image}
                >
                  {!!bulletPointState.bulletPoint.image && (
                    <img
                      style={{
                        height: 'inherit',
                        width: 'inherit',
                        objectFit: 'cover'
                      }}
                      src={
                        bulletPointState.bulletPoint.image instanceof File
                          ? window.URL.createObjectURL(
                              bulletPointState.bulletPoint.image
                            )
                          : bulletPointState.bulletPoint.image
                      }
                      alt="bullet"
                    />
                  )}
                </Box>
                Select Bullet Image: &nbsp;
                <text style={{color: 'red'}}>( Width: 190, Height: 146 )</text>
                <input
                  type="file"
                  name="bulletImage"
                  onChange={(event) => fileSelectHandler(event, 'image')}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <input type="file" />
              </Grid> */}
            </Grid>
          </CardContent>
          <CardActions disableSpacing sx={{justifyContent: 'flex-end'}}>
            <ButtonGroup
              variant="contained"
              aria-label="contained primary button group"
            >
              <Button
                color="secondary"
                onClick={() => handleBulletPointSave('cancel')}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  handleBulletPointSave(
                    bulletPointState.isUpdating ? 'update' : 'save'
                  )
                }
              >
                {bulletPointState.isUpdating ? 'Update' : 'Save'}
              </Button>
            </ButtonGroup>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default BulletPoints;
