import {Helmet} from 'react-helmet';
import {Box, Container, Grid} from '@material-ui/core';
import AccountProfile from 'src/components/account/AccountProfile';
import SettingsPassword from 'src/components/settings/SettingsPassword';
import SettingsNotifications from 'src/components/settings/SettingsNotifications';

const Account = () => (
  <>
    <Helmet>
      <title>Account | BJIT Hire Developer</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <AccountProfile />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <Box sx={{mb: 2}}>
              <SettingsPassword />
            </Box>
            <SettingsNotifications />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Account;
