import {useRoutes} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import routes from 'src/routes';
import {ToastContainer} from 'react-toastify';
import {useSelector} from 'react-redux';
import 'src/mixins/chartjs';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const App = () => {
  const {currentUser} = useSelector((state) => state);
  const routing = useRoutes(routes(currentUser));

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ToastContainer />
        {routing}
      </ThemeProvider>
    </>
  );
};

export default App;
