import React, {useState, useEffect} from 'react';
import {Grid, TextField, Container, Autocomplete} from '@material-ui/core';
import {useSelector} from 'react-redux';
import AllClients from './AllClients';
import ActiveClientsTable from './ActiveClientsTable';
import ModuleLayout from '../../ModuleLayout';

const ClientsOfBjit = ({data: newData}) => {
  const {clients} = useSelector((state) => state);
  const {
    sectionData,
    onSectionDelete,
    onOrderChange,
    index,
    lastIndex,
    onInternalDataChange,
    formik
  } = newData;
  const [activeClients, setActiveClients] = useState([]);

  const handleAutoCompleteChange = (value) => {
    if (value) {
      setActiveClients((prevState) => {
        return [...prevState, value];
      });
    }
  };

  useEffect(() => {
    onInternalDataChange(index, {
      ...formik.values.sections[index].data,
      clientsList: activeClients
    });
  }, [activeClients]);

  useEffect(() => {
    if (sectionData.clientsList?.length > 0) {
      setActiveClients(() => {
        return sectionData.clientsList;
      });
    }
  }, []);

  return (
    <>
      <ModuleLayout
        moduleTitle="Clients of BJIT"
        lastIndex={lastIndex}
        index={index}
        enableArrowButtons
        crossClickHandler={onSectionDelete}
        onOrderChange={onOrderChange}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                error={formik.touched.heading && formik.errors.heading}
                fullWidth
                helperText={formik.touched.heading && formik.errors.heading}
                label="Heading"
                name={`sections[${index}].data.heading`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.sections[index]?.data.heading || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                error={formik.touched.subHeading && formik.errors.subHeading}
                fullWidth
                multiline
                rows={2}
                helperText={formik.touched.subHeading && formik.errors.subHeading}
                label="Sub Heading"
                name={`sections[${index}].data.subHeading`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.sections[index]?.data.subHeading || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                error={formik.touched.videoLink && formik.errors.videoLink}
                fullWidth
                helperText={formik.touched.videoLink && formik.errors.videoLink}
                label="Video Link"
                name={`sections[${index}].data.videoLink`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.sections[index]?.data.videoLink || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                error={formik.touched.footerHeading && formik.errors.footerHeading}
                fullWidth
                helperText={
                  formik.touched.footerHeading && formik.errors.footerHeading
                }
                label="Footer Heading"
                name={`sections[${index}].data.footerHeading`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.sections[index]?.data.footerHeading || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                error={
                  formik.touched.footerButtonText && formik.errors.footerButtonText
                }
                fullWidth
                helperText={
                  formik.touched.footerButtonText && formik.errors.footerButtonText
                }
                label="Footer Button Text"
                name={`sections[${index}].data.footerButtonText`}
                size="small"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.sections[index]?.data.footerButtonText || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12}>
              <Autocomplete
                options={clients.filter((c) => {
                  return !activeClients.find((ac) => ac._id === c._id) && c.active;
                })}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => handleAutoCompleteChange(v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Clients Here..."
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={8}>
              <ActiveClientsTable
                activeClients={activeClients}
                setActiveClients={setActiveClients}
              />
            </Grid>
            <Grid item md={4}>
              <AllClients
                activeClients={activeClients}
                setActiveClients={setActiveClients}
              />
            </Grid>
          </Grid>
        </Container>
      </ModuleLayout>
    </>
  );
};
export default ClientsOfBjit;
