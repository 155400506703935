import {
  List,
  ListItem,
  Box,
  Button,
  Card,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import {nanoid} from 'nanoid';
import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import ApiServices from 'src/networks/ApiServices';
import AxiosServices from 'src/networks/AxiosService';

const columns = [
  {
    id: 'sections',
    label: 'Sections',
    align: 'center'
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'center',
    maxWidth: '200px'
  }
];

const LandingPageTable = ({landingPage = [], setOptionViewer}) => {
  const [rows, setRows] = useState([]);

  const createData = (_id, sections) => {
    const sectionsCell = (
      <List>
        {sections.map((s) => {
          return (
            <ListItem button key={nanoid()} sx={{px: '50px'}}>
              <ListItemText primary={s.type.replaceAll('_', ' ').toUpperCase()} />
              <ListItemSecondaryAction>
                <Switch
                  checked={s.active}
                  // eslint-disable-next-line no-use-before-define
                  onChange={() => handleActiveStatusChange(_id, s._id, !s.active)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
    const action = (
      <>
        <Button
          onClick={() =>
            setOptionViewer({
              id: 'CreateOrUpdateFrontPage',
              isUpdating: _id
            })
          }
          size="small"
          color="primary"
          variant="outlined"
        >
          Edit
        </Button>
      </>
    );

    return {_id, sections: sectionsCell, action};
  };

  const handleActiveStatusChange = (docId, secId, active) => {
    AxiosServices.put(ApiServices.sectionActive, {
      docId,
      secId,
      active
    })
      .then(({data}) => {
        setRows(() => [createData(data._id, data.sections)]);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    const dataRows = [];
    landingPage &&
      landingPage.forEach((d) => {
        dataRows.push(createData(d._id, d.sections));
      });
    setRows(dataRows);
  }, [landingPage]);

  return (
    <Card sx={{mt: 1}}>
      <Box sx={{minWidth: 1050}}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{maxWidth: column.maxWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              <>
                {rows.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={nanoid()}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <>
                            <TableCell key={nanoid()} align={column.align}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                          </>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <>
                <TableRow hover>
                  <TableCell align="center" colSpan={5}>
                    <h4>Nothing to show</h4>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default LandingPageTable;
