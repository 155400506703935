import * as Type from './actions';

const initialState = {
  refresh: false,
  currentUser: null,
  landingpage: [],
  emailList: [],
  clients: [],
  reviews: [],
  leads: [],
  partners: [],
  users: []
};

const reducer = (state = initialState, actions) => {
  switch (actions.type) {
    case Type.ADD_LANDING_PAGE:
      return {
        ...state,
        landingpage: actions.landingPage
      };
    case Type.ADD_CURRENT_USER:
      return {
        ...state,
        currentUser: actions.user
      };
    case Type.ADD_ALL_REVIEWS:
      return {
        ...state,
        reviews: actions.reviews
      };
    case Type.ADD_ALL_CLIENTS:
      return {
        ...state,
        clients: actions.clients
      };
    case Type.ADD_ALL_LEADS:
      return {
        ...state,
        leads: actions.leads
      };
    case Type.ADD_ALL_PARTNERS:
      return {
        ...state,
        partners: actions.partners
      };
    case Type.SET_REFRESH:
      return {
        ...state,
        refresh: actions.refresh
      };
    case Type.ADD_MAILS:
      return {
        ...state,
        emailList: actions.mails
      };
    case Type.ADD_ALL_USERS:
      return {
        ...state,
        users: actions.users
      };
    default:
      return state;
  }
};

export default reducer;
