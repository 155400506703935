import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  List,
  Divider
} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {generateImageUrl} from 'src/networks/utils';

const AllReviews = ({activeReviews, setActiveReviews}) => {
  const {reviews} = useSelector((state) => state);
  return (
    <div style={{maxHeight: '400px', overflowY: 'scroll', position: 'relative'}}>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
          mb: '10px'
        }}
      >
        Available Items
      </Typography>
      <List>
        {reviews
          .filter((r) => {
            return !activeReviews.find((ar) => ar._id === r._id) && r.active;
          })
          .map((data) => {
            return (
              <>
                <ListItem
                  alignItems="flex-start"
                  dense
                  button
                  sx={{cursor: 'pointer'}}
                  onClick={() =>
                    setActiveReviews((prevState) => {
                      return [...prevState, data];
                    })
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={data.name}
                      src={generateImageUrl(data.authorImage)}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={data.authorName}
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {data.title}
                          {` - `}
                        </Typography>
                        {data.body}
                      </>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </>
            );
          })}
      </List>
    </div>
  );
};

export default AllReviews;
