import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  List,
  Divider
} from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {generateImageUrl} from 'src/networks/utils';

const AllClients = ({activeClients, setActiveClients}) => {
  const {clients} = useSelector((state) => state);
  return (
    <div style={{maxHeight: '400px', overflowY: 'scroll', position: 'relative'}}>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1,
          mb: '10px'
        }}
      >
        Available items
      </Typography>
      <List>
        {clients
          .filter((c) => {
            return !activeClients.find((ac) => ac._id === c._id) && c.active;
          })
          .map((data) => {
            return (
              <>
                <ListItem
                  alignItems="flex-start"
                  dense
                  button
                  sx={{cursor: 'pointer'}}
                  onClick={() =>
                    setActiveClients((prevState) => {
                      return [...prevState, data];
                    })
                  }
                >
                  <ListItemAvatar>
                    <Avatar alt={data.name} src={generateImageUrl(data.logo)} />
                  </ListItemAvatar>
                  <ListItemText primary={data.name} />
                </ListItem>
              </>
            );
          })}
      </List>
    </div>
  );
};

export default AllClients;
